import React, { useContext, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { useParams } from 'react-router-dom';
import { Gallery, Item } from 'react-photoswipe-gallery';
import { FaPlus } from 'react-icons/fa';

import { AnimalCard } from '../components';
import { ChevronDown, LocationFilled, Image } from '../statics/icons';

import { useTranslation } from '../hooks';
import { LocaleContext } from '../components/common/LocaleContext';

import PDF from '../statics/files/Plan de acción (VF).pdf';
import PDF_CRT from '../statics/files/Presentación ejecutiva plan de acción.pdf';

export default function DestinationDetail() {
  const viewRef = useRef(null);
  const { slug } = useParams();
  const { locale } = useContext(LocaleContext);
  const { translate } = useTranslation({ locale });

  const scrollIntoView = () => {
    viewRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const imagesArray =
    typeof translate(slug, 'images') !== 'object' ? [] : translate(slug, 'images');

  const images = imagesArray.reduce(
    (acc, item, index) =>
      index >= 4
        ? { ...acc, toShow: [...acc.toShow, item] }
        : { ...acc, hidden: [...acc.hidden, item] },
    { toShow: [], hidden: [] },
  );

  const Logo =
    typeof translate(slug, 'calligraphy') !== 'function' ? 'img' : translate(slug, 'calligraphy');

  const species = typeof translate(slug, 'species') !== 'object' ? [] : translate(slug, 'species');
  const partners =
    typeof translate(slug, 'partners') !== 'object' ? [] : translate(slug, 'partners');
  const paragraphs =
    typeof translate(slug, 'implementation_partner.paragraphs') !== 'object'
      ? []
      : translate(slug, 'implementation_partner.paragraphs');
  
  const temporal = 
      <React.Fragment>
        <div className='p-0 md:p-1 ml-[-4%] flex float absolute mt-[-40%] md:mt-[-40%] text-white bg-white/[0.6] rounded-[20px]'>
          <button className='m-2 md:m-4 border-2 border-[#0d1442] rounded-lg p-2 bg-[#0d1442] text-xs md:text-base'><a href={PDF} download ='PlanAcción' rel='noopener'>Plan de acción </a></button>
          <button className='m-2 md:m-4 border-2 border-[#0d1442] rounded-lg p-2 bg-[#0d1442] text-xs md:text-base'><a href={PDF_CRT} download ='PlanAcción_VersionCorta' rel='noopener'>Plan de acción versión corta</a></button>
          <button className='m-2 md:m-4 border-2 border-[#0d1442] rounded-lg p-2 bg-[#0d1442] text-xs md:text-base'><a href='https://eur03.safelinks.protection.outlook.com/?url=https%3A%2F%2Fforms.gle%2FyksJG2rNShAHfCbi7&data=05%7C01%7Cjosafat.contreras%40undp.org%7Ca2fade56f8164ccd208a08dac342ba5d%7Cb3e5db5e2944483799f57488ace54319%7C0%7C0%7C638036990867931125%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C2000%7C%7C%7C&sdata=zOrQ7x0I2V2hdsdIOswJ1kJu0EsWnBAuwPmTQ2Ojdlk%3D&reserved=0' 
          target='_blank'  rel='noreferrer'>Invitando a la acción</a></button>
        </div>
      </React.Fragment>;

  return (
    <React.Fragment>
      <div
        className='bg-blend-darken rounded-b-full md:rounded-none mb-16 h-screen md:h-hero w-full bg-cover bg-center bg-no-repeat relative text-center flex flex-col justify-between items-center md:flex-row md:justify-center md:items-start pt-48 pb-12 px-8 md:px-0 md:pb-0'
        style={{ backgroundImage: `url(${translate(slug, 'cover')})` }}
      >
        {Logo !== 'img' ? (
          <Logo className='path-white w-full md:w-auto md:h-48' />
        ) : (
          <h1 className='text-white font-bold text-5xl'>{translate(slug, 'name')}</h1>
        )}
        <button className='border-none bg-transparent' onClick={scrollIntoView}>
          <ChevronDown className='md:hidden animate-bounce' />
        </button>
      </div>

      <div
        ref={viewRef}
        className='w-full bg-white md:bg-transparent rounded-b-16 flex justify-center items-center rounded-t-extralg xl:-mt-64 3xl:w-4/5 3xl:mx-auto'
      >
        <div className='mb-28 pt-28 px-8 md:px-14 bg-white flex justify-center rounded-t-extralg xl:w-1/2 max-w-2xl 3xl:max-w-none relative flex-col items-center'>
          <div className='text-center'>
          {slug === 'costa-oaxaca' ? temporal : null}
            <h2 className='heading text-heading mb-8 text-blue-dark font-editor-bold 3xl:text-4xl'>
              {translate('Common', 'fractions.what')} {translate(slug, 'name')}?
            </h2>
            <p className='font-light leading-6 text-justify 3xl:text-3xl'>
              {translate(slug, 'description')}
            </p>
          </div>
          {translate(slug, 'logo') && (
            <img
              className='w-full md:w-1/2 mt-8'
              src={translate(slug, 'logo')}
              alt='Logo del destino'
            />
          )}
        </div>
      </div>

      {/* <div className='lg:px-16 w-full z-negative-xs -top-28 md:top-0 md:z-0 mt-24'>
        {showVideo ? (
          <React.Fragment>
            <div
              className='lg:rounded-2xl lg:h-180 overflow-hidden mb-12 md:mb-0'
              style={{ padding: '56.25% 0 0 0', position: 'relative' }}
            >
              <iframe
                title='kuxatur trailer'
                src={destination?.video}
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                frameBorder='0'
                allow='autoplay'
              ></iframe>
            </div>
            <script src='https://player.vimeo.com/api/player.js'></script>
          </React.Fragment>
        ) : (
          <div
            className='h-180 lg:rounded-2xl bg-cover bg-center bg-no-repeat relative before:hidden lg:before:block video-border'
            style={{ backgroundImage: `url(${destination?.images[0].src})` }}
          >
            <div className='flex items-end justify-start w-full h-full p-8 lg:p-32 relative'>
              <div className='w-full h-full bg-black opacity-70 absolute inset-0 lg:rounded-2xl'></div>
              <div className='cursor-pointer z-10' onClick={toggleVideo}>
                <PlayIcon className='' />
                <div className='text-white text-4xl mt-10 w-44 font-bold leading-24 font-editor-bold'>
                  Video {destination?.name}
                </div>
              </div>
            </div>
          </div>
        )}
      </div> */}

      <div className='lg:px-20 w-full z-negative-xs -top-28 md:top-0 md:z-0 3xl:w-4/5 3xl:mx-auto'>
        <React.Fragment>
          <div
            className='lg:rounded-2xl lg:h-180 relative mb-12 md:mb-0 before:hidden xl:before:block video-border'
            style={{ padding: '56.25% 0 0 0', position: 'relative' }}
          >
            <iframe
              title='kuxatur trailer'
              className='lg:rounded-2xl'
              src={translate(slug, 'video')}
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              frameBorder='0'
              allow='autoplay'
            ></iframe>
          </div>
          <script src='https://player.vimeo.com/api/player.js'></script>
        </React.Fragment>
      </div>

      {translate(slug, 'poligon') && (
        <div className='lg:px-16 w-full z-negative-xs -top-28 md:top-0 md:z-0 mt-24 3xl:w-4/5 3xl:mx-auto'>
          <div
            className='w-full h-120 md:h-180 lg:rounded-2xl bg-contain bg-center bg-no-repeat relative before:hidden'
            style={{ backgroundImage: `url(${translate(slug, 'poligon')})` }}
          ></div>
        </div>
      )}

      <div className='flex justify-center items-center flex-wrap md:flex-nowrap px-4 my-12 3xl:w-4/5 3xl:mx-auto'>
        <a
          className='bg-blue-dark text-white py-4 px-8 rounded-lg text-center flex justify-center items-center md:mr-20 3xl:text-3xl'
          href={translate(slug, 'location')}
          target='_blank'
          rel='noreferrer'
        >
          <LocationFilled className='mr-2' />
          {translate('Common', 'cta.arrive')}
        </a>
        <a
          className='bg-blue-dark text-white py-4 px-8 rounded-lg text-center flex justify-center items-center text-base mt-8 md:mt-0 3xl:text-3xl'
          href={translate(slug, 'url')}
          target='_blank'
          rel='noreferrer'
        >
          <FaPlus className='mr-2' />
          {translate('Common', 'cta.information')}
        </a>
      </div>

      <div className='px-8 lg:px-16 w-full mt-20 md:mt-48 3xl:w-4/5 3xl:mx-auto'>
        <div className='flex justify-center items-center mb-12 md:mb-24'>
          <h2 className='heading text-heading text-blue-dark font-editor-bold 3xl:text-4xl'>
            {translate('Common', 'detail.sections.gallery')}
          </h2>
        </div>
        <Gallery>
          <div className='grid grid-cols-2 grid-rows-3 gap-2 md:gap-9'>
            {images.toShow.map((image, index) => (
              <Item
                original={image.src}
                thumbnail={image.src}
                width={image.width}
                height={image.height}
                key={`${translate(slug, 'name')}-img-${index}`}
              >
                {({ ref, open }) => (
                  <div
                    ref={ref}
                    onClick={open}
                    className={`md:col-span-1 md:row-span-1 overflow-hidden h-48 md:h-100 bg-cover bg-center bg-no-repeat md:rounded-4xl cursor-pointer hover:scale-[1.02] transition duration-300 ease-in-out ${
                      index === 0 || index === 5 ? 'col-span-2 row-span-1' : ''
                    }`}
                    style={{ backgroundImage: `url(${image.src})` }}
                  >
                    {index === images.toShow.length - 1 && (
                      <div className='relative w-full h-full flex justify-center items-center text-white flex-col'>
                        <div className='absolute w-full h-full bg-neutral-900 opacity-50'></div>
                        <div className='relative z-10 flex justify-center items-center flex-col'>
                          <Image />
                          <span>{translate('Common', 'cta.gallery')}</span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </Item>
            ))}

            {/* Hidden gallery items */}
            {images?.hidden?.map((img, index) => (
              <Item
                width={img.width}
                height={img.height}
                original={img.src}
                className='hidden'
                key={`${translate(slug, 'name')}-img-${index + 6}`}
              >
                {({ ref, open }) => (
                  <img
                    className='hidden'
                    onClick={open}
                    ref={ref}
                    src={img.src}
                    alt={`${translate(slug, 'name')}-img-${index + 6}`}
                  />
                )}
              </Item>
            ))}
            {/* END Hidden gallery items */}
          </div>
        </Gallery>
      </div>

      <div className='px-8 lg:px-16 w-full mt-48 3xl:w-4/5 3xl:mx-auto'>
        <div className='flex justify-center items-center mb-12 md:mb-24'>
          <h2 className='heading text-heading text-blue-dark font-editor-bold text-center 3xl:text-4xl'>
            {translate('Common', 'detail.sections.species')}
          </h2>
        </div>
        <div className='grid-cols-1 md:grid-cols-3 row-auto gap-8 hidden md:grid'>
          {species?.map((species, index) => (
            <AnimalCard {...species} key={`${translate(slug, 'name')}-animal-${index}`} />
          ))}
        </div>

        <div className='w-full md:hidden block'>
          <Swiper
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            slidesPerView={1.5}
            spaceBetween={24}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 16,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 32,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 32,
              },
            }}
          >
            {species.map((species, index) => (
              <SwiperSlide className='w-full' key={`${species.name}-slide`}>
                <AnimalCard {...species} key={`${translate(slug, 'name')}-animal-${index}`} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <div className='px-8 lg:px-60 w-full mt-48 3xl:w-4/5 3xl:mx-auto'>
        <div className='flex flex-col-reverse md:grid md:grid-cols-2 md:grid-rows-1 md:gap-36'>
          <div>
            <div className='flex justify-center items-center flex-col'>
              <h2 className='heading text-heading mb-8 text-blue-dark font-editor-bold text-center 3xl:text-4xl'>
                {translate('Common', 'detail.sections.partner')}
              </h2>
              <img
                className='mb-12'
                src={translate(slug, 'implementation_partner.img')}
                alt={translate(slug, 'implementation_partner.name')}
              />
              {paragraphs?.map((text, index) => (
                <p
                  className='text-justify mb-11 last:mb-0 3xl:text-3xl'
                  key={`${translate(slug, 'implementation_partner.name')}-paragraph-${index}`}
                >
                  {text}
                </p>
              ))}
              <a
                className='font-normal underline 3xl:text-3xl'
                href={translate(slug, 'implementation_partner.url')}
                target='_blank'
                rel='noreferrer'
              >
                {translate('Common', 'fractions.about')}{' '}
                {translate(slug, 'implementation_partner.name')}
              </a>
            </div>
          </div>
          <div>
            <div className='flex justify-center items-center flex-col mb-48 md:mb-0'>
              <h2 className='heading text-heading mb-8 text-blue-dark font-editor-bold text-center mb-14 3xl:text-4xl'>
                {translate('Common', 'fractions.objective')} {translate(slug, 'name')}
              </h2>
              <p className='text-justify 3xl:text-3xl'>{translate(slug, 'objective')}</p>
              <div
                className='w-full rounded-4xl h-80 bg-cover bg-center bg-no-repeat mt-11 hidden md:block'
                style={{ backgroundImage: `url(${imagesArray[0]?.src})` }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div className='px-8 lg:px-16 w-full mt-48 mb-40 3xl:w-4/5 3xl:mx-auto'>
        <div className='flex justify-start items-center mb-14'>
          <h2 className='heading text-heading text-blue-dark font-editor-bold 3xl:text-4xl'>
            {translate('Common', 'detail.sections.allies')}
          </h2>
        </div>
        <div className='w-full'>
          <Swiper
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            slidesPerView={1.5}
            spaceBetween={32}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 16,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 32,
              },
              1024: {
                slidesPerView: 6,
                spaceBetween: 32,
              },
            }}
          >
            {partners.map(partner => (
              <SwiperSlide className='w-full' key={`${partner.name}-slide`}>
                <div className='w-full flex flex-col justify-center items-center text-center mb-12'>
                  <div
                    className='h-36 w-36 rounded-full shadow-lg bg-contain bg-center bg-no-repeat'
                    style={{ backgroundImage: `url(${partner?.img})` }}
                  ></div>
                  <div className='font-semibold mt-6 mb-4 3xl:text-3xl'>{partner.name}</div>

                  <a
                    className='font-normal underline 3xl:text-2xl'
                    href={partner.url}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {translate('Common', 'cta.more')}
                  </a>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </React.Fragment>
  );
}
