export default function MayaKaanBlanco({ className }) {
  return (
    <svg
      id='Capa_1'
      data-name='Capa 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1162.98 482.94'
      className={className}
    >
      <path
        className='cls-1'
        d='M431.41,334.79C457,266,464.69,242.85,457.12,232a12.75,12.75,0,0,0-10.64-5.52c-11.51,0-29,7.75-71.63,65.22-26.63,35.91-56,81.51-81.88,121.74C277,438.13,259.77,465,247.26,482.65a4.53,4.53,0,0,1-8.13-3.54c5.17-24.7,17-61.68,24.42-85,22-69,27.57-89.9,16.63-98.53a12.4,12.4,0,0,0-7.7-2.55c-8.17,0-19.61.61-93,124.36-35.14,59.26-68,119.31-68.3,119.91a9.67,9.67,0,1,0,17,9.27c51.19-93.69,110.75-193.51,136.52-224.9-3.7,16.84-12.57,44.62-19.57,66.56-23.9,74.85-34,111.69-23.44,126.16a15.32,15.32,0,0,0,12.68,6.39c11,0,20-11.77,74.86-96.91,25.74-40,54.91-85.3,81.15-120.69,27.17-36.63,42.11-49.94,49.68-54.74-3,15.71-16,50.62-26.77,79.6-24.16,64.82-51.53,138.29-46.7,176.87a9.68,9.68,0,0,0,12.56,8c4.52-1.4,7.14-6.1,6.59-10.8-4-34.05,23.53-107.92,45.67-167.32'
        transform='translate(-110.02 -144.06)'
      />
      <path
        className='cls-1'
        d='M619.67,446.14a928.67,928.67,0,0,0,7-115.86,9.42,9.42,0,0,0-18.57-2.12c-11.69,47.92-33.7,101.11-47.84,105.14-3.74-9.05-.45-45.41,7.53-81a9.42,9.42,0,0,0-16.73-7.72c-6.68,8.91-40.09,73.31-53.75,99.8-6-25.16,5.54-69.47,11.17-86.21A9.42,9.42,0,0,0,490.7,352q-2.18,6-4.46,12a9.25,9.25,0,0,0-2.22-1c-15.35-4.54-31,1.35-44.19,16.51-17.29,19.93-29.82,57.14-20.71,77.12,3.68,8.06,10.75,12.69,19.39,12.69,12.79,0,26.23-16.63,38.37-38.48.35,11.6,2.34,22.49,7,31a14.41,14.41,0,0,0,13,7.57,14.58,14.58,0,0,0,12.83-7.93c10.46-20.37,21.41-41.43,30.9-59.37-2.34,29.53,2.62,40.39,5.65,44.17a15.59,15.59,0,0,0,12.36,6.07c16.65,0,31.64-17.48,46.87-54.84-1.23,16.66-3,34.51-5.45,52.51a81.25,81.25,0,0,0-19.33,11.22c-45.22,34.56-86.47,130.21-67.91,157.45A18.64,18.64,0,0,0,528.63,627c5.14,0,11.63-1.74,19.72-6.63,39.25-23.75,58.87-91.23,68.67-155.16,10.71-.9,20.56,4.08,30,15.1a9.42,9.42,0,1,0,14.33-12.24c-12.43-14.56-26.6-21.89-41.65-21.93m-181.16,4.34c-1,0-1.49,0-2.25-1.67-4.3-9.43,2-38.69,17.81-56.95,4.78-5.51,11.82-11.47,19.92-11.47a16.59,16.59,0,0,1,4.7.68c.21.07.41.11.62.16-15.91,37.85-33.15,67.73-40.8,69.25M538.59,604.25c-7.32,4.43-10.13,3.94-10.12,4-1.12-1.12-3.94-9.91,2.61-33,10.89-38.39,38.19-83.81,65.4-102C586.6,531,569,585.88,538.59,604.25'
        transform='translate(-110.02 -144.06)'
      />
      <path
        className='cls-1'
        d='M1265.59,301.32c-17.76-3.87-17.72-17.9-10.78-49.94,3.62-16.7,6.47-29.88-.54-38.59-2.32-2.86-6.69-6.29-14.34-6.29-11.43,0-28.67,7.66-60.81,67.4l-1.28,2.41,9-54.13A9.42,9.42,0,0,0,1170,215c-6.68,8.92-40.1,73.3-53.75,99.79-6-25.16,5.54-69.47,11.17-86.21a9.42,9.42,0,0,0-17.79-6.19c-1.45,4-2.94,8-4.46,12a9.66,9.66,0,0,0-2.22-1c-15.32-4.53-31,1.35-44.18,16.51-17.3,19.94-29.83,57.16-20.72,77.12,3.68,8.07,10.75,12.69,19.39,12.69,12.79,0,26.23-16.63,38.37-38.47.35,11.59,2.34,22.48,7,31a14.54,14.54,0,0,0,12.79,7.58h.19a14.58,14.58,0,0,0,12.83-7.94c10.4-20.25,21.28-41.18,30.73-59.05l-11,66.4a9.43,9.43,0,0,0,6.69,10.6,9.78,9.78,0,0,0,2.61.36,9.42,9.42,0,0,0,8.69-5.8c26.72-64.12,60.16-118.35,73.35-119.07.6,4.16-1.7,14.79-3.27,22-5.18,23.87-13.83,63.83,25.17,72.34a9.42,9.42,0,0,0,4-18.41m-208.2,19.55c-1,0-1.49,0-2.25-1.66-4.3-9.43,2-38.7,17.81-56.95,4.78-5.52,11.83-11.48,19.92-11.48a16.54,16.54,0,0,1,4.7.68,4.71,4.71,0,0,0,.62.16c-15.91,37.85-33.15,67.74-40.8,69.25'
        transform='translate(-110.02 -144.06)'
      />
      <path
        className='cls-1'
        d='M718.35,301.82a9.44,9.44,0,0,0-12,5.71q-2.17,6-4.45,12a9.74,9.74,0,0,0-2.23-1c-15.31-4.52-31,1.35-44.18,16.52-17.3,19.93-29.83,57.15-20.72,77.12,3.68,8.06,10.75,12.68,19.4,12.68,12.78,0,26.22-16.63,38.36-38.48.35,11.6,2.34,22.49,7,31a9.42,9.42,0,1,0,16.54-9c-12.07-22.12,1.73-75.83,8.07-94.67a9.43,9.43,0,0,0-5.84-11.9M654.21,406c-1,0-1.5,0-2.25-1.67-4.31-9.42,2-38.69,17.8-56.94,4.78-5.52,11.83-11.48,19.92-11.48a16.61,16.61,0,0,1,4.71.68,4.57,4.57,0,0,0,.61.16c-15.9,37.85-33.15,67.73-40.79,69.25'
        transform='translate(-110.02 -144.06)'
      />
      <path
        className='cls-1'
        d='M1004.35,239.71a9.43,9.43,0,0,0-12,5.71q-2.17,6-4.45,12a8.94,8.94,0,0,0-2.23-1c-15.35-4.54-31,1.34-44.18,16.5-17.29,19.93-29.83,57.15-20.72,77.12,3.69,8.07,10.75,12.69,19.4,12.69,12.78,0,26.22-16.63,38.37-38.48.34,11.6,2.34,22.49,7,31a9.42,9.42,0,1,0,16.54-9c-12.07-22.11,1.73-75.82,8.07-94.66a9.43,9.43,0,0,0-5.84-11.9M940.21,343.9c-1,0-1.49,0-2.25-1.67-4.31-9.43,2-38.69,17.8-56.94,4.78-5.52,11.82-11.48,19.92-11.48a16.73,16.73,0,0,1,4.71.69l.61.16c-15.9,37.84-33.14,67.72-40.79,69.24'
        transform='translate(-110.02 -144.06)'
      />
      <path
        className='cls-1'
        d='M966.12,390.61c-15.9,5.83-30.33,5.1-44.12-2.26-35.64-19-55.45-75.91-61.56-96.33,82.62-40.59,132.1-130.13,134.23-134a9.42,9.42,0,0,0-16.55-9c-.47.87-45.64,82.45-118.42,122.2a789.49,789.49,0,0,0,22.67-101,9.42,9.42,0,0,0-18.63-2.82,773.53,773.53,0,0,1-23.55,103.55c-35.55,118.83-51.91,176.47-52.07,177a9.42,9.42,0,0,0,6.5,11.63,9.53,9.53,0,0,0,2.57.35,9.41,9.41,0,0,0,9.06-6.85c.15-.5,13.14-46.3,41.12-140.34,10.07,28.18,31,73.65,65.73,92.2a68.56,68.56,0,0,0,32.64,8.36,77.69,77.69,0,0,0,26.87-5,9.42,9.42,0,1,0-6.49-17.68'
        transform='translate(-110.02 -144.06)'
      />
      <path
        className='cls-1'
        d='M1071.75,191.5a9.42,9.42,0,0,0-18.13-5.12c-2.34,8.18-11.79,32.18-24.25,35a9.42,9.42,0,1,0,4.18,18.37c25.53-5.81,37-43.93,38.2-48.26'
        transform='translate(-110.02 -144.06)'
      />
    </svg>
  );
}
