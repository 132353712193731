import { useContext, useState } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { useTranslation } from '../../hooks';
import { LocaleContext } from './LocaleContext';

const url =
  'https://kuxatur.us13.list-manage.com/subscribe/post?u=ba63d4780c69999e08bb8e2c0&amp;id=769e7826f4';

// use the render prop and your custom form
const CustomForm = () => {
  const [form, setForm] = useState({});
  const { locale } = useContext(LocaleContext);
  const { translate } = useTranslation({ locale });

  const handleChange = e => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  return (
    <MailchimpSubscribe
      url={url}
      render={({ subscribe, status, message }) => (
        <div className='flex flex-col'>
          <section className='flex flex-col max-w-6xl 3xl:max-w-screen-2xl mx-auto overflow-hidden bg-white rounded-lg shadow-lg md:flex-row md:h-58 3xl:h-96'>
            <div className='md:flex md:items-center md:justify-center md:w-1/2 md:bg-blue-dark '>
              <div className='px-6 3xl:px-12 py-6 md:px-8 md:py-0'>
                <h2 className='text-lg font-bold text-gray-700 md:text-gray-100 3xl:text-3xl'>
                  {translate('Common', 'newsletter.title')}{' '}
                  <span className='text-accent-orange'>Kuxatur</span>?
                </h2>

                <p className='mt-2 text-sm text-gray-600 md:text-gray-400 3xl:text-3xl'>
                  {translate('Common', 'newsletter.subtitle')}
                </p>
              </div>
            </div>

            <div className='flex items-center justify-center pb-6 px-4 md:py-4 md:w-1/2'>
              <form
                className='w-full'
                onSubmit={event => {
                  event.preventDefault();
                  subscribe(form);
                }}
              >
                <div className='flex flex-col p-1 overflow-hidden border rounded-lg w-full focus-within:ring focus-within:ring-opacity-40 focus-within:border-blue-400 focus-within:ring-blue-300'>
                  <input
                    className='3xl:text-3xl px-6 py-2 text-gray-700 placeholder-gray-500 bg-white outline-none focus:placeholder-transparent'
                    type='text'
                    name='FNAME'
                    required
                    placeholder={translate('Common', 'newsletter.name')}
                    aria-label={translate('Common', 'newsletter.name')}
                    onChange={handleChange}
                  />

                  <hr className='border-gray-200  ' />

                  <input
                    className='3xl:text-3xl px-6 py-2 text-gray-700 placeholder-gray-500 bg-white outline-none focus:placeholder-transparent'
                    type='text'
                    name='LNAME'
                    placeholder={translate('Common', 'newsletter.lastname')}
                    aria-label={translate('Common', 'newsletter.lastname')}
                    onChange={handleChange}
                  />

                  <hr className='border-gray-200  ' />

                  <input
                    className='3xl:text-3xl px-6 py-2 text-gray-700 placeholder-gray-500 bg-white outline-none focus:placeholder-transparent'
                    type='text'
                    name='ADDRESS'
                    required
                    placeholder={translate('Common', 'newsletter.organization')}
                    aria-label={translate('Common', 'newsletter.organization')}
                    onChange={handleChange}
                  />

                  <hr className='border-gray-200  ' />

                  <input
                    className='3xl:text-3xl px-6 py-2 text-gray-700 placeholder-gray-500 bg-white outline-none focus:placeholder-transparent'
                    type='email'
                    name='EMAIL'
                    required
                    placeholder={translate('Common', 'newsletter.email')}
                    aria-label={translate('Common', 'newsletter.email')}
                    onChange={handleChange}
                  />

                  <button className='3xl:text-3xl mt-4 3xl:mt-8 px-4 py-3 text-sm font-medium tracking-wider text-gray-100 uppercase transition-colors duration-200 transform bg-blue-dark rounded-lg hover:bg-gray-600 focus:bg-gray-600 focus:outline-none'>
                    {status === 'sending'
                      ? translate('Common', 'newsletter.sending')
                      : translate('Common', 'newsletter.submit')}
                  </button>
                </div>
              </form>
            </div>
          </section>
          {status === 'success' && (
            <div className='flex w-full max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-md dark:bg-blue-dark mt-8'>
              <div className='flex items-center justify-center w-12 bg-emerald-500'>
                <svg
                  className='w-6 h-6 text-white fill-current'
                  viewBox='0 0 40 40'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z' />
                </svg>
              </div>

              <div className='px-4 py-2 -mx-3'>
                <div className='mx-3'>
                  <span className='font-semibold text-emerald-500 dark:text-emerald-400'>
                    {locale === 'es' ? 'Éxito' : 'Success'}
                  </span>
                  <p className='text-sm text-gray-600 dark:text-gray-200'>
                    {translate('Common', 'newsletter.success')}
                  </p>
                </div>
              </div>
            </div>
          )}

          {status === 'error' && (
            <div className='flex w-full max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-md dark:bg-blue-dark mt-8'>
              <div className='flex items-center justify-center w-12 bg-red-500'>
                <svg
                  className='w-6 h-6 text-white fill-current'
                  viewBox='0 0 40 40'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path d='M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z' />
                </svg>
              </div>

              <div className='px-4 py-2 -mx-3'>
                <div className='mx-3'>
                  <span className='font-semibold text-red-500 dark:text-red-400'>Error</span>
                  <p className='text-sm text-gray-600 dark:text-gray-200'>{message}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    />
  );
};

export default CustomForm;
