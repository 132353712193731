export default function Location({ className }) {
  return (
    <svg
      width='38'
      height='38'
      viewBox='0 0 38 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M18.9998 3.16669C12.8723 3.16669 7.9165 8.12252 7.9165 14.25C7.9165 22.5625 18.9998 34.8334 18.9998 34.8334C18.9998 34.8334 30.0832 22.5625 30.0832 14.25C30.0832 8.12252 25.1273 3.16669 18.9998 3.16669ZM11.0832 14.25C11.0832 9.88002 14.6298 6.33335 18.9998 6.33335C23.3698 6.33335 26.9165 9.88002 26.9165 14.25C26.9165 18.81 22.3565 25.6342 18.9998 29.8934C15.7065 25.6659 11.0832 18.7625 11.0832 14.25Z'
        fill='#FB6025'
      />
      <path
        d='M18.9998 18.2084C21.186 18.2084 22.9582 16.4361 22.9582 14.25C22.9582 12.0639 21.186 10.2917 18.9998 10.2917C16.8137 10.2917 15.0415 12.0639 15.0415 14.25C15.0415 16.4361 16.8137 18.2084 18.9998 18.2084Z'
        fill='#FB6025'
      />
    </svg>
  );
}
