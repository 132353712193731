export default function Disclaimer() {
  return (
    <div className='px-8 md:px-20 pt-40 lg:pt-64 lg:px-36 xl:px-56 pb-60'>
      <div className='flex justify-center'>
        <h2 className='heading text-heading text-blue-dark mb-14 font-editor-bold'>
          Disclaimer Legal
        </h2>
      </div>
      <p className='mb-14 text-justify'>
        Integración de criterios de conservación de la biodiversidad para el sector turístico en
        México con énfasis en los ecosistemas costeros “Kuxatur” Derechos Reservados © 2022 Programa
        de las Naciones Unidas para el Desarrollo (PNUD) Montes Urales 440 Col. Lomas de
        Chapultepec, Alcaldía Miguel Hidalgo. C.P.11000, Ciudad de México.
      </p>
      <p className='mb-14 text-justify'>
        Todos los derechos están reservados. Ni esta página ni partes de ella pueden ser
        reproducidas, almacenadas mediante cualquier sistema o transmitidas, en cualquier forma o
        por cualquier medio, sea éste electrónico, mecánico, de fotocopiado, de grabado o de otro
        tipo, sin el permiso previo del Programa de las Naciones Unidas para el Desarrollo.
      </p>
      <p className='mb-14 text-justify'>
        Esta página web fue realizada en el marco del proyecto 00100949 “conservación de la
        biodiversidad para el sector turístico en México con énfasis en los ecosistemas costeros ”.
        El análisis y las conclusiones aquí expresadas no reflejan necesariamente las opiniones del
        Programa de las Naciones Unidas para el Desarrollo, de su Junta Ejecutiva, ni de sus Estados
        Miembros.
      </p>
      <p className='mb-14 text-justify'>
        El Programa de las Naciones Unidas para el Desarrollo es el principal organismo de las
        Naciones Unidas dedicado a poner fin a la injusticia de la pobreza, la desigualdad y el
        cambio climático. Trabajamos con nuestra extensa red de expertos y aliados en 170 países
        para ayudar a las naciones a construir soluciones integradas y duraderas para las personas y
        el planeta.
      </p>
      <p className='mb-14 text-justify'>
        La estrategia de Comunicación Digital del Proyecto Kuxatur “México Vivo” así como esta
        APP/WEB fueron elaboradas por el equipo de{' '}
        <a
          className='font-bold underline'
          href='https://natoure.org/'
          target='_blank'
          rel='noreferrer'
        >
          Natoure SAPI de C.V.
        </a>
        , revisados por la Secretaría de Turismo de México y el Programa de las Naciones Unidas para
        el Desarrollo (PNUD) en México.
      </p>
    </div>
  );
}
