export default function IsologoAlive({ className }) {
  return (
    <svg version='1.1' id='Capa_1' x='0px' y='0px' viewBox='180 16 998 728' className={className}>
      <path
        className='st0'
        d='M437.1,420.1h43.5v131.5h-43.5v-12.4c-8.7,10.8-22.6,16-41.5,16c-15.4,0-28.4-4.7-38.9-14.5
	c-10.2-9.7-15.4-22.9-15.4-40v-80.7h43.5V495c0,15.5,10.4,23.9,25.2,23.9c16.5,0,27-9.2,27-27.1V420.1z'
      />
      <polygon
        className='st0'
        points='647.2,551.5 597.3,551.5 570.3,516.8 543.3,551.5 493.5,551.5 545.4,484.8 495.2,420.1 545.1,420.1 
	570.3,452.7 595.6,420.1 645.4,420.1 595.3,484.8 '
      />
      <path
        className='st0'
        d='M749.9,420.1h43.5v131.5h-43.5v-12.4c-10.2,10.8-24.7,16-43.2,16c-19.1,0-35.4-6.6-49-20
	c-13.6-13.4-20.3-30-20.3-49.4c0-19.5,6.7-35.8,20.3-49.2c13.6-13.4,29.9-20.2,49-20.2c18.6,0,33.1,5.3,43.2,16V420.1z M715.3,517.9
	c10.2,0,18.6-2.9,24.9-8.7c6.4-5.8,9.6-13.7,9.6-23.4c0-9.7-3.2-17.6-9.6-23.4c-6.4-5.8-14.8-8.7-24.9-8.7
	c-10.2,0-18.6,2.9-24.9,8.7c-6.4,5.8-9.6,13.7-9.6,23.4c0,9.7,3.2,17.6,9.6,23.4C696.8,515,705.2,517.9,715.3,517.9'
      />
      <path
        className='st0'
        d='M1018.4,420.1h43.5v131.5h-43.5v-12.4c-8.7,10.8-22.6,16-41.5,16c-15.4,0-28.4-4.7-38.9-14.5
	c-10.2-9.7-15.4-22.9-15.4-40v-80.7h43.5V495c0,15.5,10.4,23.9,25.2,23.9c16.5,0,27-9.2,27-27.1V420.1z'
      />
      <path
        className='st0'
        d='M262.1,486.5c-1.1-0.2-1.1-1.7,0-1.8c61.3-9.7,63.1-64.6,63.1-64.6c-75.3,0-83.8,37.5-93.2,50.6
	c-1.1,1.6-3.8,0.8-3.8-1v-73h-47.6v155h47.6v-49.9c0-1.9,2.8-2.6,3.9-0.9c8.5,13.5,15.7,59.1,101.7,50.8
	C333.9,551.5,326.9,500.6,262.1,486.5'
      />
      <path
        className='st0'
        d='M876.4,396.6h-43.5v23.5h-23.2v37.9h23.2v46.5c0,19.2,5.2,32.3,16,40c10.7,7.4,29.9,9.7,57.4,7.1v-35.8
	c-21.2,1.1-29.9-0.8-29.9-11.3v-46.5h29.9v-37.9h-29.9V396.6z'
      />
      <path
        className='st0'
        d='M1176,459.7V420c-15,0-34,1.5-47.8,20.8v-20.7h-43.5v131.5h43.5v-49.8C1128.2,457.7,1163,459.5,1176,459.7'
      />
      <path
        className='st1'
        d='M805.4,101.1c23.2-1.6,40.7-21.7,39.1-44.9c-1.6-23.2-21.7-40.7-44.9-39.1c-23.2,1.6-40.7,21.7-39.1,44.9
	C762.1,85.2,782.2,102.7,805.4,101.1'
      />
      <path
        className='st0'
        d='M980.1,84.9c-51.9-5.1-69.1,16.9-73.7,25.3l-0.5,0.7c-0.5,0.8-1.4,1.3-2.4,1.2c-32.1-4.2-59.3-1.4-84.8,4.9v0
	c-0.1,0-0.1,0-0.2,0c-0.7,0.2-1.3,0.3-2,0.5c-0.2,0-0.3,0.1-0.5,0.1c-3,0.8-6,1.6-9,2.4c0,0,0,0,0,0c-0.7,0.2-1.5,0.4-2.2,0.6
	c-0.1,0-0.2,0-0.2,0.1c-23.9,7.1-46.8,16.6-71.6,25.6c-0.1,0-0.2,0.1-0.3,0.1c-0.8,0.3-1.6,0.6-2.4,0.9c-0.2,0.1-0.5,0.2-0.7,0.2
	c-0.7,0.2-1.4,0.5-2.1,0.7c-0.3,0.1-0.5,0.2-0.8,0.3c-0.7,0.3-1.5,0.5-2.2,0.8c-0.2,0.1-0.4,0.1-0.6,0.2c-0.9,0.3-1.8,0.6-2.7,0.9
	c-0.3,0.1-0.6,0.2-0.8,0.3c-0.6,0.2-1.3,0.4-1.9,0.6c-0.4,0.1-0.8,0.3-1.2,0.4c-0.5,0.2-1.1,0.4-1.6,0.5c-0.4,0.1-0.8,0.3-1.3,0.4
	c-0.5,0.2-1,0.3-1.6,0.5c-0.4,0.1-0.8,0.3-1.3,0.4c-0.6,0.2-1.2,0.4-1.8,0.6c0,0,0,0,0,0c-2.8,0.7-5.5,1.5-8.2,2.5
	c-1,0.4-1.8-1-0.8-1.6c36.1-22.6,24.4-58.9,40.6-62.6c4.4-1,5.2-4.4,1.7-7.2c-8.5-6.8-22.4-10.8-33.2-7.3
	c-21.7,7.1-25.7,37.6-28.7,47.8c-0.3,1.2-1.8,1-2.3-0.2c-9.7-47.8-91.4-75-143.3-3.4c-0.5,0.7-1.1,1.5-1.6,2.3
	c-0.1,0.1-0.2,0.2-0.3,0.4c-6.3,9.1-12.2,19.9-17.5,32.7c-0.1,0.3-0.2,0.5-0.3,0.8c-0.7,1.7-1.4,3.4-2,5.1c-5-0.9-10-2-15.2-3.1
	c-0.3-0.1-0.7-0.2-1-0.2c-1.3-0.3-2.5-0.6-3.8-0.9c-0.4-0.1-0.7-0.2-1.1-0.3c-1.3-0.3-2.7-0.6-4-0.9c-0.1,0-0.2-0.1-0.3-0.1
	c-4.4-1-8.9-2.1-13.5-3.3c-0.3-0.1-0.6-0.2-0.9-0.2c-1.3-0.3-2.7-0.7-4-1c-0.4-0.1-0.9-0.2-1.3-0.3c-1.3-0.4-2.7-0.7-4.1-1.1
	c-0.3-0.1-0.7-0.2-1-0.3c-4.6-1.3-9.4-2.6-14.2-4c-0.2,0-0.3-0.1-0.5-0.1c-1.4-0.4-2.9-0.8-4.3-1.3c-0.5-0.1-0.9-0.3-1.4-0.4
	c-1.4-0.4-2.8-0.8-4.2-1.3c-0.5-0.1-0.9-0.3-1.4-0.4c-1.5-0.5-2.9-0.9-4.4-1.4c-0.1,0-0.3-0.1-0.4-0.1c-7.4-2.4-15.1-4.9-22.9-7.6
	c-5.1-1.8-10.4,2-10.4,7.4c0,0.6,0,1.2,0,1.8c0,105.8,94.3,191.5,210.6,191.5c69.2,0,130.7-30.4,169-77.3
	c4.4-5.4,8.9-10.8,13.5-16.2c9.4-11,18.1-23.2,26-36.4c0.1,0,0.2,0,0.2,0.1c28.2-43.1,50.4-80.7,75.7-85c0.9-0.1,1.9-0.2,2.9-0.3
	c1.7-0.2,3.6,1.1,3.2,3c-3.4,17.3-12.3,87.1,53.6,133.3c1.2,0.8,2.8,0.2,3.1-1.2c3.8-16,20-96.1-28.8-130c-0.7-0.5-0.4-1.7,0.5-1.7
	c21.5,0.1,45.5-28.4,52-36.7C982,86.4,981.3,85,980.1,84.9 M499.5,228c-0.6,0.9-2,0.3-1.9-0.7c1.1-10.6,5.5-38.1,21.7-60.8
	c8.4,4.3,26.6,11.8,54.3,14.6c-22.6,8-55.6,31.8-67.5,40.7C503.7,223.6,501.3,225.6,499.5,228 M539.5,215.2c-0.9,0-1.2-1.1-0.6-1.7
	l22.3-20.6c14.9,1.8,47.3-2,47.3-2C575.1,214.6,547.4,215.5,539.5,215.2 M664.7,212.3c-16.7,36.9-56.1,46.1-68.9,48.1
	c-1.4,0.2-2.2-1.4-1.3-2.4c28-30,34.8-61,37.2-81.4c12.8-2.3,26.6-5.8,41.4-10.6C674.3,177.8,672.6,194.9,664.7,212.3 M713.6,218.8
	c-0.8,1.6-3.1,1.2-3.5-0.5c-5-20.8-32.1-33.2-32.1-33.2l35.2-24.5C724.1,191.4,717.8,210.7,713.6,218.8'
      />
      <path
        className='st0'
        d='M554.2,627.1c13.5-36.4,17.6-48.6,13.6-54.4c-1.3-1.9-3.3-2.9-5.6-2.9c-6.1,0-15.3,4.1-37.9,34.5
	c-14.1,19-29.6,43.1-43.3,64.4c-8.4,13.1-17.5,27.3-24.2,36.6c-1.5,2.1-4.8,0.7-4.3-1.9c2.7-13.1,9-32.6,12.9-44.9
	c11.6-36.5,14.6-47.5,8.8-52.1c-1.1-0.9-2.6-1.3-4.1-1.3c-4.3,0-10.4,0.3-49.2,65.7c-18.6,31.3-35.9,63.1-36.1,63.4
	c-1.4,2.5-0.4,5.6,2,6.9c0.8,0.4,1.6,0.6,2.4,0.6c1.8,0,3.6-1,4.5-2.7c27.1-49.5,58.5-102.3,72.2-118.9c-2,8.9-6.6,23.6-10.3,35.2
	c-12.6,39.6-18,59-12.4,66.7c1.6,2.2,4,3.4,6.7,3.4c5.8,0,10.6-6.2,39.6-51.2c13.6-21.1,29-45.1,42.9-63.8
	c14.4-19.4,22.3-26.4,26.3-28.9c-1.6,8.3-8.4,26.8-14.2,42.1c-12.8,34.3-27.2,73.1-24.7,93.5c0.4,3.1,3.5,5.2,6.6,4.2
	c2.4-0.7,3.8-3.2,3.5-5.7C527.9,697.6,542.5,658.5,554.2,627.1'
      />
      <path
        className='st0'
        d='M741.6,667.3c3.6-0.2,5.9-3.8,4.8-7.2l-0.9-2.5c-0.2-0.6-0.8-0.9-1.4-0.8c-0.7,0.1-1.9,0.3-3.5,0.3
	c0.4-9.4-1.4-18.6-7.6-23.2c-4.6-3.4-9.8-3.7-14-0.7c-2.5,1.8-4.3,4.6-5.2,7.8c-3.1,3.1-5.7,7.8-7.8,12.9l-0.1,0
	c-15.6,26.4-24.1,33.7-27.8,35.7c-1.7,1-2.9,0.2-3.6-0.4c-5.7-7.1-3-26,5.8-38.1c3.6-5,9.2-10.2,15.3-6.8l2-3.6
	c1.7-3.1,0-7.1-3.5-7.6c-7.7-1.1-15.6,3.1-22.1,12c-2.6,3.6-4.9,8-6.8,12.8l-0.1,0c-7.7,20-12.9,28.9-15.9,32.9
	c-0.8,1.1-2.6,0.7-2.7-0.7c-1.1-8.5,3.1-26.3,9.1-42.3c0.5-1.4,1-2.6,1.4-3.7c1.1-2.8-0.5-5.9-3.4-6.8c-2.6-0.7-5.2,0.8-6.2,3.3
	c-0.4,1.1-0.9,2.4-1.5,3.8c-12.3,31-26.2,51.5-32,51.5c-1.1,0-1.9-0.4-2.8-1.4c-0.7-0.9-1.4-2-1.9-3.3c10.7-18.6,21.9-38.4,22-38.7
	c1.4-2.5,0.5-5.6-1.9-7c-2.5-1.4-5.6-0.5-7,1.9c-0.1,0.2-7.4,13.1-15.7,27.7c-0.3-8.2,0.3-17.3,1.2-24c0.1-0.6-0.3-1.1-0.9-1.2
	l-2.4-0.3c-1.5-0.2-2.9,0.1-4.1,0.8c0,0-0.1,0-0.1,0c-0.1,0.1-0.2,0.1-0.3,0.2c-0.3,0.2-0.5,0.4-0.8,0.6c-0.1,0.1-0.3,0.2-0.4,0.4
	c-0.2,0.2-0.4,0.5-0.6,0.7c-0.1,0.1-0.1,0.2-0.2,0.3c-0.2,0.2-0.3,0.5-0.4,0.8c-1.2,2.4-1.8,5.8-4.3,10.6
	c-9.7,16.5-25.3,36.8-36.4,36.8c-0.7,0-1.7,0-2.2-2.8c-0.4-1.8-0.4-4-0.2-6.4c21.3-8,33.1-23.3,35.1-34.5c1-5.5-0.4-10-3.7-12.7
	c-2.5-2-7.1-3.7-14.7-0.6c-13.8,5.8-30.4,36.9-26.6,56.2c1.4,6.9,6,11,12.2,11c14.9,0,30-18.3,39.6-32.8c0.3,4,0.8,8,1.7,11.6
	c-3.9,6.8-7.7,13.2-10.7,18.2c-1.5,2.4-0.7,5.6,1.7,7c0.8,0.5,1.7,0.7,2.6,0.7c1.7,0,3.4-0.9,4.4-2.5c2.1-3.5,4.5-7.6,7.1-12
	c0,0,0,0,0,0c2.8,3.2,6.4,4.9,10.5,4.9c7.6,0,15.4-8.3,22.3-19.1c0.1,6,1.4,11.1,4.6,14.3c2.1,2.1,4.8,3,7.7,2.4
	c3.5-0.7,7.8-3.6,14.2-15.4c1.1,3.6,3,6.8,5.7,9.3c2.1,2,4.7,3,7.4,3c0.6,0,1.2,0,1.8-0.1c4.5-0.7,11.4-3.8,23.5-20.5
	c0.9,17.1,11.6,18.5,15,18.5c8.6,0,16.2-8.1,20.8-22.2c0.9-2.6,1.7-5.7,2.3-8.9C740.1,667.4,740.9,667.4,741.6,667.3 M575.2,653.3
	c1.9-0.8,3.2-1,3.8-1c0.3,0,0.4,0,0.5,0c0.2,0.2,0.6,1.7-0.4,4.7c-2.2,7.1-9.6,15.9-21.7,22.1C561.3,667.9,568.3,656.2,575.2,653.3
	 M727.3,673.1c-3.4,10.4-8.2,15.2-11.1,15.2c-1,0-3.9,0-4.7-7.5c-0.8-7.9,1.5-17.2,4.3-24.1c2.8,4.7,7.4,8,13.3,9.6
	C728.7,668.6,728.1,670.8,727.3,673.1 M723.7,649.5c-1.2-3.6-0.1-7.1,1.2-8c0.1-0.1,0.2-0.1,0.3-0.1c0.5,0,1.1,0.4,1.6,0.7
	c2.3,1.7,3.7,7,3.5,13.8C727,654.8,724.7,652.6,723.7,649.5'
      />
      <path
        className='st0'
        d='M659.8,626.1c5,0,9.1-4.1,9.1-9.1s-4.1-9.1-9.1-9.1c-5,0-9.1,4.1-9.1,9.1S654.8,626.1,659.8,626.1'
      />
      <path
        className='st0'
        d='M840.3,636.8c-0.3-2.8-3.3-4.8-6.1-4.2c-0.9,0.2-1.7,0.4-2.6,0.6c9-26.6,25-68.7,25.2-69.2
	c0.9-2.3-0.1-5.3-2.1-6.4h0c-2-1.1-5.2-0.5-6.9,1.4c-0.3,0.3-30.2,33.9-52.4,69.2l-1.5,2.4c-3.1,4.9-6.2,9.8-9.3,14.7
	c-17.7,5.1-29.4,9-29.6,9.1c-2.7,0.9-4.7,4.4-4,7c0.6,2.3,3.1,4,5.5,3.7c0.4,0,0.8-0.1,1.2-0.3c0.2-0.1,7-2.4,18.1-5.7
	c-12.9,19.7-25.2,36.6-35,43.8c-2.4,1.8-3.2,5.5-1.7,7.8c1,1.5,3,2.3,4.8,2.1c0.9-0.1,2-0.5,2.8-1.1c13.1-9.7,28.3-31.7,44.5-57
	c7.4-2.1,15.8-4.3,24.7-6.5c-5.6,20.1-7.9,31.5-8,32c-0.6,2.8,1.5,5.8,4.2,6.1c2.8,0.3,5.9-2.1,6.5-4.9c0-0.1,2.5-12.3,8.4-33.3
	c0.2-0.8,0.5-1.8,0.8-2.7c2.6-0.6,5.2-1.2,7.8-1.7C838.3,642.8,840.7,639.5,840.3,636.8 M819.5,635.9c-6.9,1.7-13.6,3.4-19.8,5
	c1.1-1.7,2.2-3.5,3.3-5.2l1.5-2.4c10.1-16,21.7-31.6,31.4-43.8C830.3,604.7,823.7,622.9,819.5,635.9'
      />
      <path
        className='st0'
        d='M888.5,613.2c-1.6,0.2-3.4,1-4.6,2.1c-1.5,1.4-2.5,3.6-2.4,5.4c0.1,3.3,3.2,5.8,6.7,5.4c3.7-0.5,7-4,6.9-7.5
	C895,615.4,891.8,612.8,888.5,613.2'
      />
      <path
        className='st0'
        d='M991.8,643.7c-0.1-0.2-0.2-0.3-0.3-0.5c-1.8-2.7-5.7-2.9-7.6-0.3c-8.7,11.9-18.9,22.2-26.8,22.2
	c-0.7,0-1.7,0-2.2-2.8c-0.4-1.8-0.4-4-0.2-6.4c21.3-8,33.1-23.3,35.1-34.5c1-5.5-0.4-10-3.7-12.7c-2.5-2-7.1-3.7-14.7-0.6
	c-10.5,4.4-22.7,23.6-26.1,41c-0.8,0.3-1.5,0.4-2.1,0.1c-2.5-0.9-4.4-6-1.3-15c0.9-2.5-0.4-5.5-2.7-6.5c-2.3-1-5.6,0.2-7,2.4
	l-14.2,22l-1.2-18.2c-0.2-2.2-2-4.2-4.2-4.6c-2.2-0.3-4.8,1-6,3.1L896,650.9c-0.1,0.2-0.3,0.4-0.4,0.6
	c-5.6,10.8-11.3,14.8-14.6,15.2c-1.5,0.2-2.5,0-3.2-0.6c-0.4-0.4-0.6-0.8-0.5-1.9c0.2-6.4,5.6-17.6,7.8-21.4
	c1.4-2.6,0.5-6.2-1.9-7.4c-2.4-1.2-6.1,0.1-7.5,2.7c-0.3,0.5-4.6,8.4-7.3,16.8c-0.2,0.3-0.4,0.6-0.6,0.9
	c-1.2,2.4-7.4,13.4-15.6,14.4c-1.6,0.2-2-0.1-2.5-0.9c-1.4-2-1.7-5.8-1.2-10.9c6.2-7.7,13-17.2,21.8-30.5c0.7-1.1,1.6-2.4,2.5-3.8
	c16-24.1,33.3-51.4,32-64.3c-0.3-3.1-2.7-5.6-5.7-5.9c-10.3-0.9-33.4,28.1-48.6,59.9c-4.1,8.7-8.4,21.1-10.7,31.5
	c-3.3,14.5-3.1,25.1,0.7,30.8c2.6,3.8,7,5.7,12,5l0-1.6l0,1.6c6.4-0.8,11.8-4.4,15.9-8.6c0.6,0.9,1.2,1.6,1.8,2.2
	c2.7,2.4,6.9,3.6,11.2,3l0,0c8.2-1,16.9-8.9,23.2-20.5c0.1-0.1,0.2-0.2,0.2-0.3l2.3-4l1.1,16.6c0.1,2.2,1.9,4.2,4,4.5
	c0.5,0.1,1.1,0.1,1.6,0l0,0c1.6-0.2,3.4-1.3,4.4-2.8l12.7-19.6c1.6,4,4.6,6.8,7.9,8c1.9,0.7,3.8,0.9,5.8,0.6
	c0.1,1.4,0.2,2.7,0.5,3.9c1.4,6.9,6,11,12.2,11c12.6,0,25.4-13.1,34.8-26C993.2,647.7,993.1,645.5,991.8,643.7 M975.4,617.6
	c1.9-0.8,3.2-1,3.8-1c0.3,0,0.4,0,0.5,0c0.2,0.2,0.6,1.7-0.4,4.7c-2.2,7.1-9.6,15.9-21.7,22.1C961.5,632.2,968.5,620.5,975.4,617.6
	 M860.2,617.7c12.3-25.7,25.4-42,32.6-49.2c-5,13.9-21.9,39.2-29,50c-1,1.4-1.8,2.7-2.6,3.9c-2.5,3.8-4.8,7.3-7,10.5
	C856.1,627.3,858.2,622.1,860.2,617.7'
      />
    </svg>
  );
}
