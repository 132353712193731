import React, { useContext, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { FaFacebookSquare, FaTwitter, FaInstagram } from 'react-icons/fa';

import { LocaleContext } from './common/LocaleContext';
import { useTranslation } from '../hooks';
import Turismo from '../statics/images/sec_turismo.png';
import Gef from '../statics/images/gef.png';
import Undp from '../statics/images/undp.png';
import Amigos from '../statics/images/amigos.png';
import Isla from '../statics/images/isla.png';
import Wwf from '../statics/images/wwf.png';
import { Logo, Menu, WorldLight, ChevronDown } from '../statics/icons';

export default function Layout({ children }) {
  const [show, setShow] = useState(false);
  const [showLocale, setShowLocale] = useState(false);
  const location = useLocation();
  const { locale, setLocale } = useContext(LocaleContext);

  const { translate } = useTranslation({ locale });

  const useDark = ['/nosotros', '/descargo-de-responsabilidad'].includes(location.pathname);

  const toggleMenu = () => setShow(prev => !prev);
  const toggleLocale = () => setShowLocale(prev => !prev);

  const selectLocale = locale => {
    setLocale(locale);
    setShowLocale(false);
  };

  const closeMenu = () => setShow(false);
  return (
    <React.Fragment>
      <header className='absolute w-full z-10 navbar'>
        <nav className='flex justify-between align-center py-7 px-8 md:px-20 3xl:w-4/5 3xl:mx-auto'>
          <div className='flex items-center'>
            <ul className='flex items-center'>
              <Link to='/' className='mr-12 3xl:w-64'>
                <li>
                  <Logo className={`w-32 3xl:w-64 ${useDark ? 'path-blue-dark' : ''}`} />
                </li>
              </Link>
              <li>
                <button
                  className='flex font-bold text-white items-center relative 3xl:text-3xl'
                  onClick={toggleLocale}
                >
                  <WorldLight
                    className={`mr-1 ${useDark ? 'path-stroke-blue-dark circle-blue-dark' : ''}`}
                  />
                  <span className={`mr-2 uppercase mr-1 ${useDark ? 'text-blue-dark' : ''}`}>
                    {locale}
                  </span>
                  <ChevronDown
                    className={`path-white w-1/5 h-full transition-all ${
                      showLocale ? 'rotate-180' : ''
                    } ${useDark ? 'path-blue-dark' : ''}`}
                  />
                </button>
                <div
                  className={`transition ease-in-out duration-300 absolute z-20 py-2 mt-2 bg-white rounded-md shadow-xl ${
                    showLocale ? 'visible opacity-100' : 'invisible opacity-0'
                  }`}
                >
                  <div
                    onClick={() => selectLocale('es')}
                    className={`block px-4 py-3 text-lg text-blue-dark capitalize transition-colors duration-200 transform  hover:bg-gray-100 ${
                      locale === 'es' ? 'font-bold' : ''
                    }`}
                  >
                    Español 🇲🇽
                  </div>
                  <hr className='border-gray-200 ' />
                  <div
                    onClick={() => selectLocale('en')}
                    className={`block px-4 py-3 text-lg text-blue-dark capitalize transition-colors duration-200 transform  hover:bg-gray-100 ${
                      locale === 'en' ? 'font-bold' : ''
                    }`}
                  >
                    English 🇺🇸
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div>
            <div className='relative xl:hidden inline-block '>
              <button
                onClick={toggleMenu}
                className='xl:hidden relative z-10 block p-2 text-gray-700 border border-transparent rounded-md focus:border-blue-500 focus:ring-opacity-40 focus:ring-blue-300 focus:ring focus:outline-none'
              >
                <Menu className={`${useDark ? 'path-blue-dark' : ''}`} />
              </button>

              <div
                className={`transition ease-in-out duration-300 absolute right-0 z-20 w-56 py-2 mt-2 bg-white rounded-md shadow-xl ${
                  show ? 'visible opacity-100' : 'invisible opacity-0'
                }`}
              >
                <NavLink
                  to='/'
                  onClick={closeMenu}
                  className={({ isActive }) =>
                    `3xl:text-3xl block px-4 py-3 text-lg text-blue-dark capitalize transition-colors duration-200 transform  hover:bg-gray-100 ${
                      isActive ? 'font-bold' : 'font-semibold'
                    }`
                  }
                >
                  Inicio
                </NavLink>
                <hr className='border-gray-200 ' />

                <NavLink
                  onClick={closeMenu}
                  to='/nosotros'
                  className={({ isActive }) =>
                    `3xl:text-3xl block px-4 py-3 text-lg text-blue-dark capitalize transition-colors duration-200 transform  hover:bg-gray-100 ${
                      isActive ? 'font-bold' : 'font-semibold'
                    }`
                  }
                >
                  {translate('Home', 'navbar.about')}
                </NavLink>
                <hr className='border-gray-200  ' />

                <NavLink
                  onClick={closeMenu}
                  to='/destino/baja-california-sur'
                  className={({ isActive }) =>
                    `3xl:text-3xl block px-4 py-3 text-lg text-blue-dark capitalize transition-colors duration-200 transform  hover:bg-gray-100 ${
                      isActive ? 'font-bold' : 'font-semibold'
                    }`
                  }
                >
                  Baja California Sur
                </NavLink>

                <hr className='border-gray-200 ' />

                <NavLink
                  onClick={closeMenu}
                  to='/destino/costa-oaxaca'
                  className={({ isActive }) =>
                    `block px-4 py-3 text-lg text-blue-dark capitalize transition-colors duration-200 transform  hover:bg-gray-100 ${
                      isActive ? 'font-bold' : 'font-semibold'
                    }`
                  }
                >
                  Costa Oaxaca
                </NavLink>

                <hr className='border-gray-200 ' />
                <NavLink
                  onClick={closeMenu}
                  to='/destino/maya-kaan'
                  className={({ isActive }) =>
                    `3xl:text-3xl block px-4 py-3 text-lg text-blue-dark capitalize transition-colors duration-200 transform  hover:bg-gray-100 ${
                      isActive ? 'font-bold' : 'font-semibold'
                    }`
                  }
                >
                  Maya Ka’an
                </NavLink>
              </div>
            </div>
            <ul className='hidden xl:flex'>
              <NavLink
                to='/nosotros'
                className={({ isActive }) =>
                  `3xl:text-3xl ${isActive ? 'font-bold active-link' : 'font-semibold'}`
                }
              >
                <li
                  className={`mr-14 ${
                    useDark ? 'text-blue-dark' : 'text-white'
                  } relative before:w-0`}
                >
                  {translate('Home', 'navbar.about')}
                </li>
              </NavLink>
              <NavLink
                to='/destino/baja-california-sur'
                className={({ isActive }) =>
                  `3xl:text-3xl ${isActive ? 'font-bold active-link' : 'font-semibold'}`
                }
              >
                <li
                  className={`mr-14 ${
                    useDark ? 'text-blue-dark' : 'text-white'
                  } relative before:w-0`}
                >
                  Baja California Sur
                </li>
              </NavLink>
              <NavLink
                to='/destino/costa-oaxaca'
                className={({ isActive }) =>
                  `3xl:text-3xl ${isActive ? 'font-bold active-link' : 'font-semibold'}`
                }
              >
                <li
                  className={`mr-14 ${
                    useDark ? 'text-blue-dark' : 'text-white'
                  } relative before:w-0`}
                >
                  Costa Oaxaca
                </li>
              </NavLink>
              <NavLink
                to='/destino/maya-kaan'
                className={({ isActive }) =>
                  `3xl:text-3xl ${isActive ? 'font-bold active-link' : 'font-semibold'}`
                }
              >
                <li className={`${useDark ? 'text-blue-dark' : 'text-white'} relative before:w-0`}>
                  Maya Ka’an
                </li>
              </NavLink>
            </ul>
          </div>
        </nav>
      </header>
      {children}
      <footer className='md:h-footer w-full top-shadow py-8'>
        <div className='flex justify-center items-center flex-col 3xl:w-4/5 3xl:mx-auto'>
          <div className='text-blue-dark 3xl:text-3xl'>{translate('Common', 'footer.follow')}</div>
          <div className='flex justify-around flex-wrap lg:grid lg:gap-4 lg:grid-cols-3 mt-7 px-8 md:px-0 w-full md:w-1/2 lg:w-auto'>
            <a
              href='https://www.facebook.com/Kuxatur'
              target='_blank'
              rel='noreferrer'
              className='flex items-center 3xl:text-3xl'
            >
              <FaFacebookSquare className='text-2xl mr-4 3xl:text-3xl' />
              Facebook
            </a>
            <a
              href='https://twitter.com/KuxaturMX'
              target='_blank'
              rel='noreferrer'
              className='flex items-center justify-center 3xl:text-3xl'
            >
              <FaTwitter className='text-2xl mr-4 3xl:text-3xl' />
              Twitter
            </a>
            <a
              href='https://www.instagram.com/Kuxatur_Mexico'
              target='_blank'
              rel='noreferrer'
              className='flex items-center 3xl:text-3xl'
            >
              <FaInstagram className='text-2xl mr-4 3xl:text-3xl' />
              Instagram
            </a>
          </div>

          <div className='sm:w-max my-10 flex md:justify-around md:w-full'>
            <div className='flex object-scale-down md:w-52'><img src={Turismo} alt='secretaria-de-turismo' className='object-contain' /></div>
            <div className='flex'><img src={Gef} alt='gef' className='object-scale-down' /></div>
            <div className='flex'><img src={Undp} alt='undp' className='object-scale-down' /></div>
            <div className='flex object-scale-down '><img src={Amigos} alt='amigos' className='object-contain'/></div>
            <div className='flex'><img src={Isla} alt='isla' className='object-scale-down' /></div>
            <div className='flex'><img src={Wwf} alt='wwf' className='object-scale-down' /></div>
          </div>
          <div className='flex text-center'>
            <Link
              to='/descargo-de-responsabilidad'
              className='mr-6 font-normal underline 3xl:text-3xl'
            >
              Disclaimer Legal
            </Link>
          </div>
          <div className='mt-6 font-normal text-center 3xl:text-3xl'>
            Copyright © 2022. Kuxatur. Todos los derechos reservados.
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}
