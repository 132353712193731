import { CostaOaxBlanco } from '../../statics/icons';

import oaxacaCover from '../../statics/images/oaxaca/oax_cover.jpg';
import oaxPoligono from '../../statics/images/oaxaca/oax_poligono.jpg';
import conanp from '../../statics/images/oaxaca/CONANP.jpeg';
import oaxPartner from '../../statics/images/oaxaca/wwf.jpg';
import oaxaca1 from '../../statics/images/oaxaca/oaxaca1.jpg';
import oaxaca2 from '../../statics/images/oaxaca/oaxaca2.jpg';
import oaxaca3 from '../../statics/images/oaxaca/oaxaca3.jpg';
import oaxaca4 from '../../statics/images/oaxaca/oaxaca4.jpg';
import oaxaca5 from '../../statics/images/oaxaca/oaxaca5.jpg';
import oaxaca6 from '../../statics/images/oaxaca/oaxaca6.jpg';
import oaxaca7 from '../../statics/images/oaxaca/oaxaca7.jpg';
import oaxaca8 from '../../statics/images/oaxaca/oaxaca8.jpg';
import oaxaca9 from '../../statics/images/oaxaca/oaxaca9.jpg';
import oaxaca10 from '../../statics/images/oaxaca/oaxaca10.jpg';

const oaxaca = {
  name: 'Costa Oaxaca',
  description:
    'Los esfuerzos para promover un turismo sustentable y responsable  en la costa de Oaxaca (OAX), abarcando desde Lagunas de Chacahua hasta Bahías de Huatulco, no son nuevos. La declaración de Huatulco como Centro Integralmente Planeado en la década de 1970 comenzó a generar flujo turístico al área y sitios cercanos como destinos  de sol y playa. Asimismo, los esfuerzos para implementar medidas de conservación en el área (por ejemplo, los santuarios tortugueros) datan de inicios de los 90.  Sin embargo, varias organizaciones han trabajado en la región desde dicha década, consolidando proyectos de producción sustentable y turismo de naturaleza, incluyendo iniciativas comunitarias y ecoturísticas. En 2016, el Fondo Oaxaqueño para la Conservación de la Naturaleza, Fundación ADO y Ayuda en Acción unieron fuerzas para definir una visión en el área para los próximos 10 años con respecto a cómo influir la promoción del ecoturismo, generando un diagnóstico de necesidades y un Plan de Acción para lograrlo. Esto se complementa con las experiencias del Fondo Mundial para la Naturaleza (WWF, por sus siglas en inglés), la Comisión Nacional de Áreas Naturales Protegidas (CONANP), las municipalidades y otras instituciones que trabajan con las comunidades locales para involucrarse en las actividades de conservación y resolver conflictos ambientales y sociales.',
  cover: oaxacaCover,
  calligraphy: CostaOaxBlanco,
  poligon: oaxPoligono,
  location: 'https://goo.gl/maps/rDvJvgQMV3iZow8s7',
  path: '/destino/costa-oaxaca',
  video: 'https://player.vimeo.com/video/672239796?h=75226b74e7&title=0&byline=0&portrait=0',
  species: [
    {
      name: 'Ballena Jorobada',
      scientific_name: 'Megaptera novaeangliae',
      link: 'https://enciclovida.mx/especies/34429-megaptera-novaeangliae',
      img: 'https://inaturalist-open-data.s3.amazonaws.com/photos/118234627/large.jpg',
      description:
        'Las ballenas jorobadas llegan a medir 15 metros de largo y pesar hasta 41 toneladas, las hembras suelen ser un poco más grandes que los machos, en esta especie de ballena particularmente las aletas pectorales son extremadamente largas y de color blanco en la parte ventral (de ahí su nombre científico mega=grande, ptera=ala, aleta).Son catalogadas como organismos sociables, por lo cual no es raro que se les encuentre en grupos de 7 a 10 individuos. Las migraciones de las ballenas jorobadas abarcan todo el litoral del Pacífico mexicano (se han registrado individuos migrando desde las costas de California hasta las aguas cercanas a Costa Rica).',
    },
    {
      name: 'Arrecifes de coral',
      scientific_name: 'Género Pocillopora',
      link: 'https://enciclovida.mx/busquedas/resultados?utf8=%E2%9C%93&busqueda=basica&id=&nombre=Pocillopora&button=',
      img: 'https://static.inaturalist.org/photos/6093060/large.jpg',
      description:
        'Los corales son de gran importancia para los ecosistemas marinos al ser indicadores de una dinámica costera estable en relación a los sedimentos, la temperatura y la contaminación ya que son organismos muy sensibles a los cambios en estos factores ambientales. Los corales del género Pocillopora son corales pétreos y comúnmente se les denomina coral coliflor. Los corales Pocillopora además, son especies constructoras de arrecifes (los arrecifes son barreras o “bancos” sumergidos que pueden ser rocosos, artificiales, de coral o mixtos) que son estructuras que modifican la dirección y velocidad de las corrientes marinas, brindando protección frente a fenómenos hidrometeorológicos; además de brindar refugio y protección a diversas especies de peces e invertebrados en su etapa juvenil y adulta.',
    },
    {
      name: 'Tortugas marinas',
      scientific_name: 'Dermochelys coriaceae, Lepidochelys olivaceae',
      link: 'https://enciclovida.mx/especies/27181-dermochelys-coriacea',
      img: 'https://inaturalist-open-data.s3.amazonaws.com/photos/1929630/large.jpg',
      description:
        'Las tortugas marinas son especies fundamentales para los ecosistemas marinos y costeros; durante su ciclo de vida forman parte de las cadenas alimenticias que mantienen la salud y productividad de estos ecosistemas; desde la etapa de anidación y eclosión, donde los huevos y crías son parte del alimento de la fauna silvestre, hasta la etapa juvenil y adulta en el mar, donde son consumidoras de de distintos organismos como medusas, salpas, peces, crustáceos y algunas algas en el caso de las omnívoras; y siendo presa también, en todos los estados de su desarrollo, desde pulpos, cangrejos y peces en la etapa juvenil, hasta depredadores como el pez dorado o más grandes como los tiburones o las orcas en la etapa adulta. Las tortugas laúd y la golfina son dos de las especies más representativas de la costa de Oaxaca, la laúd es rara de ver y es la más grande del mundo, pesando hasta 500 kilogramos; mientras que la golfina llega por miles en ciertas playas (fenómeno conocido como arribazón) y es una de las especies más pequeña, pesando hasta 50 kilogramos.',
    },
    {
      name: 'Manglares',
      scientific_name: 'Rizophora mangle',
      link: 'https://enciclovida.mx/especies/166804-rhizophora-mangle',
      img: 'https://www.grupo-epm.com/site/portals/20/Images/Manglar%201.jpg?ver=2019-07-26-133711-540',
      description:
        'Los manglares son ecosistemas que juegan un importante rol ecológico en las franjas costeras de las zonas tropicales y subtropicales en donde encuentran condiciones óptimas para su desarrollo. Se ha reconocido internacionalmente que los manglares mantienen una productividad muy alta. Se destacan distintas funciones ecológicas y socioeconómicas de estos ecosistemas únicos en el planeta. Son ecosistemas de transición entre los ecosistemas terrestres y los ecosistemas marinos que los circundan. Se desarrollan en lagunas costeras, zonas estuarinas y desembocaduras de ríos.',
    },
    {
      name: 'Nutria de río',
      scientific_name: 'Lontra longicaudis annectens',
      link: 'https://enciclovida.mx/especies/38228-lontra-longicaudis-subsp-annectens',
      img: 'https://static.inaturalist.org/photos/54624427/large.jpg',
      description:
        'El lobito de río (Lontra longicaudis) también conocido como nutria del noroeste, nutria neotropical, perro de agua (llanos de Colombia y Venezuela), pisua o mallu-puma, o bien como nutria de río, nutria colilarga, nutria de río sudamericana o simplemente nutria, es una especie de la familia Mustelidae3 (subfamilia Lutrinae). [*] Mide entre 1.1-1.4 m y pesa entre 20-26 kg. Cuerpo largo, delgado y de forma cilíndrica. Ancho del cuello igual al del cráneo. Cabeza plana y redondeada con hocico corto y ancho. Patas cortas con membranas interdigitales y desnudas en la planta. Con dos capas de pelo. Región dorsal color café oscuro o rojizo pálido, ventral color pardo grisáceo, garganta amarillenta y región pectoral color crema.',
    },
  ],
  implementation_partner: {
    name: 'WWF',
    img: oaxPartner,
    url: 'https://wwf.panda.org/',
    paragraphs: [
      'WWF es una de las organizaciones independientes de conservación más grandes y con mayor experiencia en el mundo. WWF nació en 1961 y es conocida por el símbolo del panda. Cuenta con una red mundial que trabaja en más de 100 países. Su misión es detener la degradación del ambiente natural del planeta y construir un futuro en el que los seres humanos vivan en armonía con la naturaleza, conservando la diversidad biológica mundial, asegurando que el uso de los recursos naturales renovables sea sostenible y promoviendo la reducción de la contaminación y del consumo desmedido. Para saber más visite: www.wwf.org.mx y www.panda.org',
    ],
  },
  partners: [
    {
      name: 'CONANP',
      url: 'https://www.gob.mx/conanp',
      img: conanp,
    },
  ],
  images: [
    { src: oaxaca1, width: '1024', height: '1365' },
    { src: oaxaca2, width: '1024', height: '768' },
    { src: oaxaca3, width: '1024', height: '683' },
    { src: oaxaca4, width: '1024', height: '683' },
    { src: oaxaca5, width: '1024', height: '683' },
    { src: oaxaca6, width: '1024', height: '1536' },
    { src: oaxaca7, width: '1024', height: '1365' },
    { src: oaxaca8, width: '720', height: '960' },
    { src: oaxaca9, width: '960', height: '720' },
    { src: oaxaca10, width: '575', height: '1024' },
  ],
};

export default oaxaca;
