import { useState, useRef, useEffect } from 'react';
import { ChevronDown } from '../../statics/icons';

export default function AnimalCard({ img, name, description, scientific_name, link }) {
  const collapseContainerRef = useRef(null);
  const [height, setHeight] = useState(0);
  const [collapse, setCollapse] = useState(false);
  const hangleToggle = () => setCollapse(prev => !prev);

  useEffect(() => {
    if (collapseContainerRef?.current) {
      setHeight(collapseContainerRef.current.scrollHeight);
    }
  }, []);

  return (
    <div className='w-full flex flex-col items-center pb-8 md:pb-0'>
      <div
        className='w-full h-56 md:h-120 bg-cover bg-center bg-no-repeat rounded-2xl'
        style={{ backgroundImage: `url(${img})` }}
      ></div>
      <h2 className='text-center text-2xl mt-8 mb-2 font-bold 3xl:text-4xl'>{name}</h2>
      <h4 className='text-center text-lg italic font-normal mb-7 3xl:text-3xl'>
        {scientific_name}
      </h4>
      <p
        ref={collapseContainerRef}
        className={`text-justify mb-7 transition-all 3xl:text-3xl ${
          collapse ? 'overflow-auto' : 'overflow-hidden line-clamp-4'
        }`}
        style={{ height: collapse ? `${height}px` : '100px' }}
      >
        {description}
      </p>
      {link && (
        <a href={link} target='_blank' rel='noreferrer' className='underline mb-4 3xl:text-2xl'>
          Ver más
        </a>
      )}
      <div className='cursor-pointer' onClick={hangleToggle}>
        <ChevronDown className={`path-blue-dark transition-all ${collapse ? 'rotate-180' : ''}`} />
      </div>
    </div>
  );
}
