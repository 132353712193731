import { useEffect, useState } from 'react';
import dataEn from '../locales/en';
import dataEs from '../locales/es';

const useTranslation = ({ locale }) => {
  const data = {
    en: dataEn,
    es: dataEs,
  };
  const [currentData, setCurrentData] = useState({});

  useEffect(() => {
    setCurrentData(data[locale]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const resolvePath = (object, path, defaultValue) =>
    path.split('.').reduce((o, p) => (o ? o[p] : defaultValue), object);

  const translate = (file, key) => {
    if (file in currentData) {
      return resolvePath(currentData[file], key);
    }
    return key;
  };

  return { translate };
};

export default useTranslation;
