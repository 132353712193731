export default function WorldLight({ className }) {
  return (
    <svg
      width='25'
      height='23'
      viewBox='0 0 25 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <circle cx='12.6269' cy='11.3601' r='9.77778' stroke='white' strokeWidth='1.22222' />
      <path
        d='M20.2139 4.98612C19.3182 5.81238 18.1934 6.48244 16.9176 6.94973C15.6418 7.41701 14.2456 7.6703 12.8261 7.692C11.4066 7.7137 9.99782 7.50328 8.6977 7.07536C7.39757 6.64745 6.2373 6.01231 5.29753 5.2141'
        stroke='white'
        strokeWidth='1.22222'
      />
      <path
        d='M20.354 17.8665C19.45 16.9921 18.2939 16.2824 16.9723 15.7907C15.6507 15.2989 14.1978 15.0379 12.7226 15.027C11.2473 15.0162 9.78796 15.2559 8.45376 15.7281C7.11956 16.2004 5.94515 16.893 5.0185 17.7539'
        stroke='white'
        strokeWidth='1.22222'
      />
      <path d='M12.627 1.58228V21.1378' stroke='white' strokeWidth='1.22222' />
      <path d='M22.4047 11.3601H2.84912' stroke='white' strokeWidth='1.22222' />
    </svg>
  );
}
