import { Layout, LocaleProvider } from './components';
import { Home, DestinationDetail, About, Disclaimer } from './pages';
import { Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import { useEffect } from 'react';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <LocaleProvider>
      <Layout>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/destino/:slug' element={<DestinationDetail />} />
          <Route path='/nosotros' element={<About />} />
          <Route path='/descargo-de-responsabilidad' element={<Disclaimer />} />
        </Routes>
      </Layout>
    </LocaleProvider>
  );
}

export default App;
