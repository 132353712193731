export default function ChevronDown({ className }) {
  return (
    <svg
      width='46'
      height='46'
      viewBox='0 0 46 46'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <g opacity='0.6' clipPath='url(#clip0_62_837)'>
        <path
          d='M5.405 9.92871L23 27.4854L40.595 9.92871L46 15.3337L23 38.3337L0 15.3337L5.405 9.92871Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_62_837'>
          <rect width='46' height='46' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
