import { CostaOaxBlanco } from '../../statics/icons';

import oaxacaCover from '../../statics/images/oaxaca/oax_cover.jpg';
import oaxPoligono from '../../statics/images/oaxaca/oax_poligono.jpg';
import conanp from '../../statics/images/oaxaca/CONANP.jpeg';
import oaxPartner from '../../statics/images/oaxaca/wwf.jpg';
import oaxaca1 from '../../statics/images/oaxaca/oaxaca1.jpg';
import oaxaca2 from '../../statics/images/oaxaca/oaxaca2.jpg';
import oaxaca3 from '../../statics/images/oaxaca/oaxaca3.jpg';
import oaxaca4 from '../../statics/images/oaxaca/oaxaca4.jpg';
import oaxaca5 from '../../statics/images/oaxaca/oaxaca5.jpg';
import oaxaca6 from '../../statics/images/oaxaca/oaxaca6.jpg';
import oaxaca7 from '../../statics/images/oaxaca/oaxaca7.jpg';
import oaxaca8 from '../../statics/images/oaxaca/oaxaca8.jpg';
import oaxaca9 from '../../statics/images/oaxaca/oaxaca9.jpg';
import oaxaca10 from '../../statics/images/oaxaca/oaxaca10.jpg';

const oaxaca = {
  name: 'Costa Oaxaca',
  description:
    'Efforts to promote sustainable and responsible tourism on the coast of Oaxaca (OAX), ranging from Lagunas de Chacahua to Bahías de Huatulco, are not new. The declaration of Huatulco as an Integrally Planned Center in the 1970s began to generate tourism flow to the area and nearby sites as sun and beach destinations. Likewise, efforts to implement conservation measures in the area (e.g., turtle sanctuaries) date back to the early 1990s.  However, several organizations have worked in the region since that decade, consolidating sustainable production and nature tourism projects, including community and ecotourism initiatives. In 2016, the Oaxacan Fund for Nature Conservation, ADO Foundation and Ayuda en Acción joined forces to define a vision in the area for the next 10 years regarding how to influence the promotion of ecotourism, generating a needs diagnosis and an Action Plan to achieve it. This is complemented by the experiences of the World Wildlife Fund (WWF), the National Commission of Natural Protected Areas (CONANP), municipalities and other institutions that work with local communities to get involved in conservation activities and resolve environmental and social conflicts.',
  cover: oaxacaCover,
  calligraphy: CostaOaxBlanco,
  poligon: oaxPoligono,
  location: 'https://goo.gl/maps/rDvJvgQMV3iZow8s7',
  path: '/destino/costa-oaxaca',
  video: 'https://player.vimeo.com/video/672239796?h=75226b74e7&title=0&byline=0&portrait=0',
  species: [
    {
      name: 'Humpback Whale',
      scientific_name: 'Megaptera novaeangliae',
      link: 'https://enciclovida.mx/especies/34429-megaptera-novaeangliae',
      img: 'https://inaturalist-open-data.s3.amazonaws.com/photos/118234627/large.jpg',
      description:
        'Humpback whales can measure up to 15 meters long and weigh up to 41 tons, females are usually a little larger than males, in this whale species particularly the pectoral fins are extremely long and white on the ventral part (hence its scientific name mega=large, ptera=fin, flipper), they are classified as sociable organisms, so it is not uncommon to find them in groups of 7 to 10 individuals. Humpback whale migrations cover the entire Mexican Pacific coast (individuals have been recorded migrating from the coasts of California to the waters near Costa Rica).',
    },
    {
      name: 'Coral reefs',
      scientific_name: 'Genus Pocillopora',
      link: 'https://enciclovida.mx/busquedas/resultados?utf8=%E2%9C%93&busqueda=basica&id=&nombre=Pocillopora&button=',
      img: 'https://static.inaturalist.org/photos/6093060/large.jpg',
      description:
        'Corals are of great importance to marine ecosystems as they are indicators of stable coastal dynamics in relation to sediment, temperature and pollution since they are organisms very sensitive to changes in these environmental factors. Corals of the genus Pocillopora are stony corals and are commonly referred to as cauliflower corals. Pocillopora corals are also reef-building species (reefs are submerged barriers or "banks" that can be rocky, artificial, coral or mixed), which are structures that modify the direction and speed of marine currents, providing protection against hydrometeorological phenomena, in addition to providing shelter and protection to various species of fish and invertebrates in their juvenile and adult stages.',
    },
    {
      name: 'Sea turtles',
      scientific_name: 'Dermochelys coriaceae, Lepidochelys olivaceae',
      link: 'https://enciclovida.mx/especies/27181-dermochelys-coriacea',
      img: 'https://inaturalist-open-data.s3.amazonaws.com/photos/1929630/large.jpg',
      description:
        'Sea turtles are fundamental species for marine and coastal ecosystems; during their life cycle they are part of the food chains that maintain the health and productivity of these ecosystems; from the nesting and hatching stage, where eggs and hatchlings are part of the food of wildlife, to the juvenile and adult stage in the sea, where they are consumers of different organisms such as jellyfish, salps, fish, crustaceans and some algae in the case of omnivores; They are also prey at all stages of their development, from octopuses, crabs and fish in the juvenile stage, to predators such as goldfish or larger predators such as sharks or killer whales in the adult stage. Leatherback and olive ridley turtles are two of the most representative species of the coast of Oaxaca, the leatherback is rare to see and is the largest in the world, weighing up to 500 kilograms; while the olive ridley arrives by thousands on certain beaches (phenomenon known as upwelling) and is one of the smallest species, weighing up to 50 kilograms.',
    },
    {
      name: 'Mangroves',
      scientific_name: 'Rizophora mangle',
      link: 'https://enciclovida.mx/especies/166804-rhizophora-mangle',
      img: 'https://www.grupo-epm.com/site/portals/20/Images/Manglar%201.jpg?ver=2019-07-26-133711-540',
      description:
        'Mangroves are ecosystems that play an important ecological role in the coastal strips of tropical and subtropical zones where they find optimal conditions for their development. It has been internationally recognized that mangroves maintain a very high productivity. Different ecological and socioeconomic functions of these unique ecosystems on the planet are highlighted. They are transition ecosystems between terrestrial ecosystems and the marine ecosystems that surround them. They develop in coastal lagoons, estuarine zones and river mouths.',
    },
    {
      name: 'River otter',
      scientific_name: 'Lontra longicaudis annectens',
      link: 'https://enciclovida.mx/especies/38228-lontra-longicaudis-subsp-annectens',
      img: 'https://static.inaturalist.org/photos/54624427/large.jpg',
      description:
        'The river otter (Lontra longicaudis), also known as the northwestern otter, neotropical otter, water dog (plains of Colombia and Venezuela), pisua or mallu-puma, or as river otter, colilarga otter, South American river otter or simply otter, is a species of the family Mustelidae3 (subfamily Lutrinae). [It measures between 1.1-1.4 m and weighs between 20-26 kg. Long, slender, cylindrical body. Neck width equal to that of the skull. Flat and rounded head with short and wide muzzle. Short legs with interdigital membranes and bare on the sole. With two layers of hair. Dorsal region dark brown or pale reddish, ventral grayish brown, yellowish throat and cream colored pectoral region.',
    },
  ],
  implementation_partner: {
    name: 'WWF',
    img: oaxPartner,
    url: 'https://wwf.panda.org/',
    paragraphs: [
      "WWF is one of the largest and most experienced independent conservation organizations in the world. WWF was founded in 1961 and is known by the symbol of the panda. It has a global network working in more than 100 countries. Its mission is to halt the degradation of the planet's natural environment and build a future in which humans live in harmony with nature by conserving the world's biological diversity, ensuring that the use of renewable natural resources is sustainable, and promoting the reduction of pollution and wasteful consumption. To learn more visit: www.wwf.org.mx and www.panda.org",
    ],
  },
  partners: [
    {
      name: 'CONANP',
      url: 'https://www.gob.mx/conanp',
      img: conanp,
    },
  ],
  images: [
    { src: oaxaca1, width: '1024', height: '1365' },
    { src: oaxaca2, width: '1024', height: '768' },
    { src: oaxaca3, width: '1024', height: '683' },
    { src: oaxaca4, width: '1024', height: '683' },
    { src: oaxaca5, width: '1024', height: '683' },
    { src: oaxaca6, width: '1024', height: '1536' },
    { src: oaxaca7, width: '1024', height: '1365' },
    { src: oaxaca8, width: '720', height: '960' },
    { src: oaxaca9, width: '960', height: '720' },
    { src: oaxaca10, width: '575', height: '1024' },
  ],
};

export default oaxaca;
