import { BCS as BCSCalligraphy } from '../../statics/icons';

import bajaCover from '../../statics/images/baja/baja_cover.jpg';
import bajaPoligono from '../../statics/images/baja/baja_poligono.png';
import islaCover from '../../statics/images/baja/isla.png';
import setue from '../../statics/images/baja/SETUE.png';
import sepuimm from '../../statics/images/baja/SEPUIMM.jpg';
import ayuntamientoLaPaz from '../../statics/images/baja/ayuntamiento_la_paz.png';
import ayuntamientoLosCabos from '../../statics/images/baja/ayuntamiento_los_Cabos.jpg';
import espirituSanto from '../../statics/images/baja/espiritu_santo.jpg';
import lalaguna from '../../statics/images/baja/LaLaguna.png';
import caboPulmo from '../../statics/images/baja/cabo_pulmo.png';
import conabio from '../../statics/images/baja/Conabio.png';
import caboSanLucas from '../../statics/images/baja/cabo_san_lucas.png';
import uabcs from '../../statics/images/baja/logo_uabcs.png';
import baja1 from '../../statics/images/baja/baja1.jpg';
import baja2 from '../../statics/images/baja/baja2.jpg';
import baja3 from '../../statics/images/baja/baja3.jpg';
import baja4 from '../../statics/images/baja/baja4.jpg';
import baja5 from '../../statics/images/baja/baja5.jpg';
import baja6 from '../../statics/images/baja/baja6.jpg';
import baja7 from '../../statics/images/baja/baja7.jpg';
import baja8 from '../../statics/images/baja/baja8.png';
import baja9 from '../../statics/images/baja/baja9.jpg';
import baja10 from '../../statics/images/baja/baja10.jpg';
import baja11 from '../../statics/images/baja/baja11.jpg';

const bajaSur = {
  name: 'Baja California Sur',
  description:
    'Baja California Sur (BCS) is a tourist destination committed to the care of its ecosystems and biodiversity, as evidenced by its 14 natural protected areas (NPAs), which represent 42 percent of the total territory of Baja California Sur. Specifically, the southern region of the state, where world-class tourist destinations such as Los Cabos and La Paz are located, is where tourists find a wide diversification of activities related to ecotourism, rural, adventure and cultural tourism; It is also in this region where there is a greater diversity of ecosystems with unique characteristics such as mangroves, dunes, the only rocky reef system in the Gulf of California, low deciduous forest, pine-oak forest, oases and islands; all of these are part of the Biodiversity Circuit in these 7 PNAs (6 federal and 1 state). The main tourist attractions in these ecosystems are humpback whale watching, swimming with sea lions, swimming with whale sharks, sport fishing, bird watching, diving and snorkeling in the reef system, among others. In the cultural aspect, there are the historic towns, fishing communities, Southcalifornian ranches and the route of the missions.',
  cover: bajaCover,
  calligraphy: BCSCalligraphy,
  poligon: bajaPoligono,
  objective:
    'To strengthen Baja California Sur as a tourist destination committed to the use, management and preservation of its ecosystems, biodiversity and culture; highlighting the integration of communities in the development and diversification of their economic activities related to nature tourism, the incorporation of biodiversity conservation criteria and the capabilities of rural communities.',
  path: '/destino/baja-california-sur',
  video: 'https://player.vimeo.com/video/672240142?h=47d38f367c&title=0&byline=0&portrait=0',
  species: [
    {
      name: 'Humpback Whale',
      scientific_name: 'Megaptera novaeangliae',
      link: 'https://enciclovida.mx/especies/34429-megaptera-novaeangliae',
      img: 'https://inaturalist-open-data.s3.amazonaws.com/photos/118234627/large.jpg',
      description:
        'Humpback whales can measure up to 15 meters long and weigh up to 41 tons, females are usually a little larger than males, in this whale species particularly the pectoral fins are extremely long and white on the ventral part (hence its scientific name mega=large, ptera=fin, flipper), they are classified as sociable organisms, so it is not uncommon to find them in groups of 7 to 10 individuals. Humpback whale migrations cover the entire Mexican Pacific coast (individuals have been recorded migrating from the coasts of California to the waters near Costa Rica).',
    },
    {
      name: 'Mascarita Bajacaliforniana',
      scientific_name: 'Geothlypis beldingi',
      link: 'https://enciclovida.mx/especies/36195-geothlypis-beldingi',
      img: 'https://inaturalist-open-data.s3.amazonaws.com/photos/2960825/large.jpg',
      description:
        'The peninsular mascarita is the largest of the mascaritas, with a total length of between 13.5 and 14 cm and a relatively large and heavy bill. The beldingi subspecies, the southernmost, has a blackish bill, flesh-colored legs, lemon-yellow rim behind the mask, olive-yellow crown and upperparts, strong yellow throat and underparts with an ochre bath on the flanks. Females of these populations have olive-colored head and upperparts, pale eye-ring and pale brownish-beige eyebrow, yellow throat and breast becoming whiter on the breast; yellow underparts and flanks with a brownish dip. Immature males similar to females, but with black mottled face, may resemble adult males in spring but with whitish feather edges. In the goldmani subspecies the males are generally paler, with olive-colored upperparts, yellow throat and underparts, with a brownish dip on the flanks. Males have a pale lemon-yellow to almost white rim behind the mask. Juveniles in both subspecies have olive-brown head and upperparts with two pale cinnamon wing bars, throat and underparts dirty grayish beige. Their song is rich and powerful, similar to that of cardinals, and sounds like whi-ti-wichu whi-ti-wichu whi-ti-wichu whi-ti-wichu or whi ti-wi-chu whi ti-wi-chu whi-chu whi-chu, or whi-t-wi-chu, whi-t-wi-chu, whi-t-wich, the main phrase repeated about two or three times (Bryant 1889, Wilbur 1987, Howell & Webb 1995).',
    },
    {
      name: 'Yellow-billed cuckoo',
      scientific_name: 'Coccyzus americanus',
      link: 'https://enciclovida.mx/especies/35856-coccyzus-americanus',
      img: 'https://inaturalist-open-data.s3.amazonaws.com/photos/32994043/large.jpg',
      description:
        'The American cockatoo (Coccyzus americanus) is a species of cuculiform bird belonging to the cuckoo family that lives in the Americas.',
    },
    {
      name: 'Blue marlin',
      scientific_name: 'Makaira nigricans',
      link: 'https://enciclovida.mx/especies/29626-makaira-nigricans',
      img: 'https://inaturalist-open-data.s3.amazonaws.com/photos/161629363/large.jpg',
      description:
        'The blue marlin (Makaira nigricans), also known as the Atlantic blue marlin, is a species of marlin that inhabits cold waters of the Atlantic and other contiguous seas. It is found both in the north (Canada) and south (Argentina), as well as in the west and east (from the Azores to South Africa). Their migratory routes are for foraging and breeding (spawning occurs in summer and autumn) and can be transatlantic or trans-equatorial. Individuals of this species are solitary and are considered quite aggressive, so they are desired as trophies for sport fishing. They reach sexual maturity between two and four years of age.',
    },
  ],
  implementation_partner: {
    name: 'ISLA',
    img: islaCover,
    url: 'https://www.isla.org.mx/',
    paragraphs: [
      "Conservación del Territorio Insular Mexicano, A.C. (ISLA) is a national Civil Society Organization founded on November 16, 1995 by a group of scientists, members of the Conca'ac tribe (first president and founding member) and naturalists in the Gulf of California Islands. In 1998 ISLA in association with USAID/The Nature Conservancy carried out the project Marine and Island Complex of Southeastern Baja California Sur: Isla Espiritu Santo and Loreto Bay National Park, Parks in Peril, USAID, TNC, Packard Foundation and HOMELAND Foundation. He has participated in the development of management programs for: Gulf of California Islands, Loreto Bay National Park, San Lorenzo Archipelago Marine Zone National Park, and Espiritu Santo Island Island Complex. In 2001 ISLA prepared the Land Tenure Study in Loreto as part of the Land Use and Ecological Management Program (POEL). In 2001 it carried out the Strategic Planning for the Environmental Institution of Baja California Sur, SEMARNAT, BCS government and Homeland Foundation. One of the projects that set a precedent in Mexico was the Conservation Process and Expropriation Process of the Archipelago of Espiritu Santo 1995-2003 with the Ejido Bonfil Community, consolidated on January 16, 2003 with the improvement of the expropriation process as Mexican patrimony. Activities supported by SEMARNAT/CONANP/REFORMA AGRARIA, The Nature Conservancy, the David and Lucile Packard Foundation, the HOMELAND Foundation, Ejido Bonfil de La Paz and ISLA.",
      'Between 2002 and 2004, ISLA developed a process of participatory construction of good practices of marinas where a Manual of Good Practices of Marinas was generated, input for the construction of the Mexican Standard, Standard NMX-AA-119-SCFI-2006. In 2018 participated in its update published in June 2020 NMX-AA-119-SCFI-2020.In 2010, ISLA advised the Vizcaino-Valle de los Cirios Circuit to obtain the certification of the Mexican Standard NMX-AA-133-SCFI-2006 Requirements and Specifications for Ecotourism Sustainability supported by ISLA, SEMARNAT, MARISLA and CONAFOR. In 2019 began the certified ecotourism circuit in the bay of La Paz, certified by NMX-AA-133-SCFI-2013. Participating in its update in 2021.',
      'From 2006 to 2022, ISLA is the Focal Point of the Earth Charter in Baja California Sur, promoting the principles and values in ejido communities and academic institutions. In 2015 signed in Ensenada, BC. an agreement with the General Directorate of Education in Marine Sciences, SEP to include the 52 schools in coastal areas in the national territory.',
    ],
  },
  partners: [
    {
      name: 'Secretaría de Turismo y Economía (SETUE)',
      url: 'https://setuesbcs.gob.mx/',
      img: setue,
    },
    {
      name: 'Secretaría de Planeación Urbana, Infraestructura, Movilidad, Medio Ambiente y Recursos Naturales (SEPUIMM)',
      url: 'https://sepuimm.bcs.gob.mx/ ',
      img: sepuimm,
    },
    {
      name: 'Municipio de La Paz',
      url: 'https://lapaz.gob.mx/',
      img: ayuntamientoLaPaz,
    },
    {
      name: 'Municipio de Los Cabos',
      url: 'https://www.loscabos.gob.mx/',
      img: ayuntamientoLosCabos,
    },
    {
      name: 'Parque Nacional Zona Marina del Archipiélago del Espíritu Santo, BCS/ CONANP',
      url: 'https://simec.conanp.gob.mx/ficha.php?anp=141&=11',
      img: espirituSanto,
    },
    {
      name: 'Reserva de la Biósfera Sierra La Laguna, BCS/ CONANP',
      url: 'https://simec.conanp.gob.mx/ficha.php?anp=120&=11',
      img: lalaguna,
    },
    {
      name: 'Parque Nacional Cabo Pulmo, BCS/ CONANP',
      url: 'https://simec.conanp.gob.mx/ficha.php?anp=111&=11',
      img: caboPulmo,
    },
    {
      name: 'Comisión Nacional para el Conocimiento y Uso de la Biodiversidad (CONABIO)',
      url: 'https://www.gob.mx/conabio',
      img: conabio,
    },
    {
      name: 'APFF Cabo San Lucas, BCS',
      url: 'https://simec.conanp.gob.mx/ficha.php?anp=113&=11 ',
      img: caboSanLucas,
    },
    {
      name: 'Universidad Autónoma de Baja California Sur',
      url: 'https://www.uabcs.mx/',
      img: uabcs,
    },
  ],
  images: [
    { src: baja1, width: '1024', height: '680' },
    { src: baja2, width: '1024', height: '760' },
    { src: baja3, width: '1024', height: '680' },
    { src: baja4, width: '1024', height: '682' },
    { src: baja5, width: '1024', height: '683' },
    { src: baja6, width: '1024', height: '1536' },
    { src: baja7, width: '1024', height: '683' },
    { src: baja8, width: '1024', height: '753' },
    { src: baja9, width: '1024', height: '768' },
    { src: baja10, width: '1024', height: '576' },
    { src: baja11, width: '1024', height: '683' },
  ],
};

export default bajaSur;
