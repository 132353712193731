export default function KuxaturLogo({ className }) {
  return (
    <svg viewBox='0 0 121 25' fill='none' xmlns='http://www.w3.org/2000/svg' className={className}>
      <path
        d='M32.6879 6.63721H37.8139V22.1268H32.6879V20.6706C31.6626 21.9405 30.0227 22.5601 27.8009 22.5601C25.9898 22.5601 24.4521 22.0026 23.2217 20.8563C22.0258 19.7099 21.4106 18.161 21.4106 16.1473V6.63721H26.5366V15.4663C26.5366 17.2943 27.767 18.2851 29.5098 18.2851C31.4576 18.2851 32.6879 17.2009 32.6879 15.0944V6.63721Z'
        fill='white'
      />
      <path
        d='M57.075 22.1268H51.1972L48.019 18.0374L44.8408 22.1268H38.9629L45.0797 14.2578L39.168 6.63721H45.0458L48.019 10.4789L50.9921 6.63721H56.87L50.9582 14.2578L57.075 22.1268Z'
        fill='white'
      />
      <path
        d='M68.8101 6.63733H73.9361V22.1269H68.8101V20.6708C67.6142 21.9407 65.9053 22.5602 63.7186 22.5602C61.4635 22.5602 59.5496 21.7858 57.9435 20.2061C56.3375 18.6264 55.5518 16.6742 55.5518 14.3821C55.5518 12.0894 56.3375 10.1692 57.9435 8.58889C59.5496 7.0092 61.4635 6.20337 63.7186 6.20337C65.906 6.20337 67.6142 6.82295 68.8101 8.09285V6.63733ZM64.7433 18.1617C65.9392 18.1617 66.9306 17.8212 67.6819 17.1395C68.4338 16.4579 68.8095 15.5285 68.8095 14.3821C68.8095 13.2358 68.4338 12.3064 67.6819 11.6247C66.93 10.9431 65.9392 10.6025 64.7433 10.6025C63.5474 10.6025 62.5559 10.9431 61.8047 11.6247C61.0534 12.3064 60.6771 13.2358 60.6771 14.3821C60.6771 15.5285 61.0528 16.4579 61.8047 17.1395C62.5566 17.8206 63.5474 18.1617 64.7433 18.1617Z'
        fill='white'
      />
      <path
        d='M99.3642 6.63721H104.49V22.1268H99.3642V20.6706C98.3389 21.9405 96.699 22.5601 94.4772 22.5601C92.6661 22.5601 91.1284 22.0026 89.898 20.8563C88.7021 19.7099 88.0869 18.161 88.0869 16.1473V6.63721H93.2129V15.4663C93.2129 17.2943 94.4433 18.2851 96.186 18.2851C98.1338 18.2851 99.3642 17.2009 99.3642 15.0944V6.63721Z'
        fill='white'
      />
      <path
        d='M12.4337 14.4617C12.3045 14.4335 12.3083 14.2667 12.4393 14.246C19.6611 13.1084 19.8743 6.63727 19.8743 6.63727C11.002 6.63727 10.003 11.0509 8.89116 12.5954C8.75884 12.7792 8.4409 12.6933 8.4409 12.4744V3.87109H2.83643V22.1269H8.4409V16.2496C8.4409 16.022 8.7764 15.9448 8.9012 16.1436C9.90018 17.7302 10.748 23.1083 20.8852 22.1269C20.8852 22.1269 20.0675 16.1311 12.4337 14.4617Z'
        fill='white'
      />
      <path
        d='M82.9985 3.87109H77.8725V6.63727H75.1389V11.0985H77.8725V16.582C77.8725 18.8433 78.4877 20.3923 79.7519 21.2909C81.0162 22.1582 83.2719 22.4373 86.5184 22.1275V17.9146C84.0238 18.0387 82.9985 17.8218 82.9985 16.5826V11.0991H86.5184V6.6379H82.9985V3.87109Z'
        fill='white'
      />
      <path
        d='M117.569 11.303V6.62915C115.804 6.62915 113.567 6.80474 111.937 9.07676V6.63668H106.811V22.1263H111.937C111.937 22.1263 111.941 16.3387 111.941 16.2609C111.94 11.0747 116.038 11.2886 117.569 11.303Z'
        fill='white'
      />
    </svg>
  );
}
