import turismo from '../statics/images/sec_turismo.png';
import amigos from '../statics/images/amigos.png';
import isla from '../statics/images/isla.png';
import gef from '../statics/images/gef.png';
import wwf from '../statics/images/wwf.png';
import undp from '../statics/images/undp.png';

import baja1 from '../statics/images/baja/baja1.jpg';
import baja2 from '../statics/images/baja/baja2.jpg';
import baja3 from '../statics/images/baja/baja3.jpg';
import baja4 from '../statics/images/baja/baja4.jpg';
import baja5 from '../statics/images/baja/baja5.jpg';
import baja6 from '../statics/images/baja/baja6.jpg';
import baja7 from '../statics/images/baja/baja7.jpg';
import baja8 from '../statics/images/baja/baja8.png';
import baja9 from '../statics/images/baja/baja9.jpg';
import baja10 from '../statics/images/baja/baja10.jpg';
import baja11 from '../statics/images/baja/baja11.jpg';

import maya1 from '../statics/images/maya/maya1.png';
import maya2 from '../statics/images/maya/maya2.png';
import maya3 from '../statics/images/maya/maya3.jpeg';
import maya4 from '../statics/images/maya/maya4.png';
import maya5 from '../statics/images/maya/maya5.png';
import maya6 from '../statics/images/maya/maya6.png';
import maya7 from '../statics/images/maya/maya7.jpeg';
import maya8 from '../statics/images/maya/maya8.png';
import maya9 from '../statics/images/maya/maya9.jpeg';
import maya10 from '../statics/images/maya/maya10.png';

import oaxaca1 from '../statics/images/oaxaca/oaxaca1.jpg';
import oaxaca2 from '../statics/images/oaxaca/oaxaca2.jpg';
import oaxaca3 from '../statics/images/oaxaca/oaxaca3.jpg';
import oaxaca4 from '../statics/images/oaxaca/oaxaca4.jpg';
import oaxaca5 from '../statics/images/oaxaca/oaxaca5.jpg';
import oaxaca6 from '../statics/images/oaxaca/oaxaca6.jpg';
import oaxaca7 from '../statics/images/oaxaca/oaxaca7.jpg';
import oaxaca8 from '../statics/images/oaxaca/oaxaca8.jpg';
import oaxaca9 from '../statics/images/oaxaca/oaxaca9.jpg';
import oaxaca10 from '../statics/images/oaxaca/oaxaca10.jpg';

const data = {
  sites: {
    'baja-california-sur': {
      name: 'Baja California Sur',
      path: '/destino/baja-california-sur',
      video: 'https://player.vimeo.com/video/672240142?h=47d38f367c&title=0&byline=0&portrait=0',
      images: [
        { src: baja1, width: '1024', height: '680' },
        { src: baja2, width: '1024', height: '760' },
        { src: baja3, width: '1024', height: '680' },
        { src: baja4, width: '1024', height: '682' },
        { src: baja5, width: '1024', height: '683' },
        { src: baja6, width: '1024', height: '1536' },
        { src: baja7, width: '1024', height: '683' },
        { src: baja8, width: '1024', height: '753' },
        { src: baja9, width: '1024', height: '768' },
        { src: baja10, width: '1024', height: '576' },
        { src: baja11, width: '1024', height: '683' },
      ],
    },
    'costa-oaxaca': {
      name: 'Costa Oaxaca',
      path: '/destino/costa-oaxaca',
      images: [
        { src: oaxaca1, width: '1024', height: '1365' },
        { src: oaxaca2, width: '1024', height: '768' },
        { src: oaxaca3, width: '1024', height: '683' },
        { src: oaxaca4, width: '1024', height: '683' },
        { src: oaxaca5, width: '1024', height: '683' },
        { src: oaxaca6, width: '1024', height: '1536' },
        { src: oaxaca7, width: '1024', height: '1365' },
        { src: oaxaca8, width: '720', height: '960' },
        { src: oaxaca9, width: '960', height: '720' },
        { src: oaxaca10, width: '575', height: '1024' },
      ],
    },
    'maya-kaan': {
      name: 'Maya Ka’an',
      path: '/destino/maya-kaan',
      images: [
        { src: maya1, width: '1024', height: '683' },
        { src: maya2, width: '1024', height: '683' },
        { src: maya3, width: '960', height: '1149' },
        { src: maya4, width: '1024', height: '683' },
        { src: maya5, width: '1024', height: '820' },
        { src: maya6, width: '1024', height: '683' },
        { src: maya7, width: '960', height: '1149' },
        { src: maya8, width: '1024', height: '683' },
        { src: maya9, width: '960', height: '1149' },
        { src: maya10, width: '1024', height: '1536' },
      ],
    },
  },
  landing: {
    partners: [
      {
        name: 'Secretaría de Turismo',
        img: turismo,
        alt: 'Turismo',
        website: 'https://www.gob.mx/sectur',
        description: {
          es: 'La Secretaría de Turismo es una de las diecinueve secretarías de Estado que, junto con la Consejería Jurídica del Ejecutivo Federal, conforman el gabinete legal del presidente de México. Es el despacho del poder ejecutivo federal encargado de formular y conducir la política de desarrollo de la actividad turística nacional.',
          en: 'The Ministry of Tourism is one of the nineteen ministries that, together with the Legal Counsel of the Federal Executive, make up the legal cabinet of the President of Mexico. It is the office of the federal executive branch in charge of formulating and conducting the development policy of the national tourism activity.',
        },
      },
      {
        name: 'GEF (Global Enviroment Facility)',
        img: gef,
        alt: 'GEF (Global Enviroment Facility)',
        website: 'https://www.thegef.org/',
        description: {
          es: 'Apoyamos la labor de los países en desarrollo para abordar los problemas medioambientales más urgentes del mundo. Organizamos nuestro trabajo en torno a cinco áreas focales -pérdida de biodiversidad, productos químicos y residuos, cambio climático, aguas internacionales y degradación del suelo- y adoptamos un enfoque integrado para apoyar sistemas alimentarios, gestión forestal y ciudades más sostenibles.',
          en: "We support the work of developing countries to address the world's most pressing environmental problems. We organize our work around five focal areas - biodiversity loss, chemicals and waste, climate change, international waters and land degradation - and take an integrated approach to supporting more sustainable food systems, forest management and cities.",
        },
      },
      {
        name: 'PNUD',
        img: undp,
        alt: 'PNUD',
        website: 'https://www.mx.undp.org/',
        description: {
          es: 'El Programa de las Naciones Unidas para el Desarrollo (PNUD) apoya a los gobiernos y sus instituciones a implementar acciones para disminuir la pobreza y la desigualdad, así como, aumentar la resiliencia ante el cambio climático. Para garantizar la calidad en todo el ciclo de proyectos se aplican las políticas y requisitos de los proyectos GEF  - PNUD en apego a los más altos estándares de calidad.',
          en: 'The United Nations Development Programme (UNDP) supports governments and their institutions to implement actions to reduce poverty and inequality, as well as to increase resilience to climate change. To ensure quality throughout the project cycle, GEF-UNDP project policies and requirements are applied in accordance with the highest quality standards.',
        },
      },
      {
        name: 'Amigos de SIan Ka’an',
        img: amigos,
        alt: 'Amigos de SIan Ka’an',
        website: 'https://www.amigosdesiankaan.org/ ',
        description: {
          es: "Amigos de Sian Ka'an es una organización de la sociedad civil líder, con más de 35 años de experiencia, dedicada a conservar las selvas más extensas de México, los ríos subterráneos más grandes del mundo, los humedales más importantes de Mesoamérica, el segundo arrecife de coral más largo en el planeta y promover el desarrollo socioeconómico de las comunidades en la Península de Yucatán.",
          en: "Amigos de Sian Ka'an is a leading civil society organization with more than 35 years of experience, dedicated to conserving Mexico's largest rainforests, the world's largest subway rivers, Mesoamerica's most important wetlands, the second longest coral reef on the planet, and promoting the socioeconomic development of communities in the Yucatan Peninsula.",
        },
      },
      {
        name: 'ISLA (Conservación del Territorio Insular Mexicano, A.C.)',
        img: isla,
        alt: 'Isla',
        website: 'https://www.isla.org.mx/ ',
        description: {
          es: 'ISLA es una organización sin fines de lucro constituida en 1995, por un grupo de naturalistas, científicos y de base comunitaria, interesados en el manejo y uso sustentable de los recursos naturales insulares, costeros y marinos de México, principalmente en la Región Golfo de California. En ISLA consideramos que las actividades de conservación y manejo de recursos naturales son integrales, sustentables y requieren de la participación de las disciplinas sociales, naturales, jurídicas, políticas y económicas. Razón por la cual se ha tomado la vinculación interinstitucional e intersectorial bajo el principio de “Unir Esfuerzos y Multiplicar Beneficios”.',
          en: 'ISLA is a non-profit organization formed in 1995 by a group of naturalists, scientists and community-based organizations interested in the management and sustainable use of Mexico\'s insular, coastal and marine natural resources, mainly in the Gulf of California Region. At ISLA we believe that conservation and natural resource management activities are integral, sustainable and require the participation of social, natural, legal, political and economic disciplines. For this reason we have taken the interinstitutional and intersectoral linkage under the principle of "Uniting Efforts and Multiplying Benefits".',
        },
      },
      {
        name: 'WWF',
        img: wwf,
        alt: 'WWF',
        website: 'www.panda.org ',
        description: {
          es: 'WWF es una de las organizaciones independientes de conservación más grandes y con mayor experiencia en el mundo. WWF nació en 1961 y es conocida por el símbolo del panda. Cuenta con una red mundial que trabaja en más de 100 países. Su misión es detener la degradación del ambiente natural del planeta y construir un futuro en el que los seres humanos vivan en armonía con la naturaleza, conservando la diversidad biológica mundial, asegurando que el uso de los recursos naturales renovables sea sostenible y promoviendo la reducción de la contaminación y del consumo desmedido.',
          en: "WWF is one of the largest and most experienced independent conservation organizations in the world. WWF was founded in 1961 and is known by the symbol of the panda. It has a global network working in more than 100 countries. Its mission is to halt the degradation of the planet's natural environment and build a future in which humans live in harmony with nature, conserving the world's biological diversity, ensuring that the use of renewable natural resources is sustainable and promoting the reduction of pollution and wasteful consumption.",
        },
      },
    ],
  },
};

export default data;
