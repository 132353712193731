export default function Indicator({ className }) {
  return (
    <svg
      width='22'
      height='22'
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <circle cx='11' cy='11' r='5.5' fill='#F9F8F4' />
      <circle cx='11' cy='11' r='10' stroke='#F9F8F4' strokeWidth='2' />
    </svg>
  );
}
