export default function Image({ className }) {
  return (
    <svg
      width='63'
      height='64'
      viewBox='0 0 63 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M55.125 55.6393V8.38928H7.875V55.6393H55.125ZM22.3125 35.9518L28.875 43.853L38.0625 32.0143L49.875 47.7643H13.125L22.3125 35.9518Z'
        fill='#F9F8F4'
      />
    </svg>
  );
}
