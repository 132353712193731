import React, { useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { FiLink2 } from 'react-icons/fi';
import { SimpleLogo } from '../statics/icons';
import cover1 from '../statics/images/maya/maya8.png';
import cover2 from '../statics/images/baja/baja9.jpg';
import cover3 from '../statics/images/baja/baja6.jpg';
import cover4 from '../statics/images/maya/maya5.png';
import { useTranslation } from '../hooks';
import { LocaleContext } from '../components/common/LocaleContext';

export default function About() {
  const { locale } = useContext(LocaleContext);
  const { translate } = useTranslation({ locale });

  const bcsSpecies =
    typeof translate('baja-california-sur', 'species') !== 'object'
      ? []
      : translate('baja-california-sur', 'species');

  const oaxSpecies =
    typeof translate('costa-oaxaca', 'species') !== 'object'
      ? []
      : translate('costa-oaxaca', 'species');

  const mayaKaanSpecies =
    typeof translate('maya-kaan', 'species') !== 'object' ? [] : translate('maya-kaan', 'species');

  const speciesBySite = {
    'baja-california-sur': bcsSpecies,
    'costa-oaxaca': oaxSpecies,
    'maya-kaan': mayaKaanSpecies,
  };

  return (
    <React.Fragment>
      <div className='px-8 md:px-20 pt-40 lg:pt-64 lg:px-36 xl:px-56 3xl:w-4/5 3xl:mx-auto 3xl:px-20'>
        <div className='flex mb-14 text-blue-dark text-2xl items-center font-bold 3xl:text-4xl'>
          <SimpleLogo className='mr-4' />
          {translate('About', 'title')}
        </div>
        <div
          className='h-80 bg-cover bg-center bg-no-repeat rounded-tl-15 rounded-br-15 mb-20 3xl:h-[30vh]'
          style={{ backgroundImage: `url(${cover1})` }}
        ></div>
        <div className='flex justify-center'>
          <h2 className='heading text-heading text-blue-dark mb-14 font-editor-bold 3xl:text-4xl'>
            {translate('About', 'sections.history.title')}
          </h2>
        </div>
        <div className='flex w-full justify-between flex-col md:flex-row'>
          <div className='md:mr-12 w-full md:w-1/2'>
            <p className='text-justify mb-12 font-normal 3xl:text-3xl'>
              {translate('About', 'sections.history.p1')}
            </p>
            <p className='text-justify mb-12 md:mb-0 font-normal 3xl:text-3xl'>
              {translate('About', 'sections.history.p2')}
            </p>
          </div>
          <p className='text-justify w-full md:w-1/2 font-normal 3xl:text-3xl'>
            {translate('About', 'sections.history.p3')}
          </p>
        </div>
      </div>

      <div className='px-8 md:px-20 lg:px-36 xl:px-56 mt-44 3xl:w-4/5 3xl:mx-auto 3xl:px-20'>
        <div
          className='h-80 bg-cover bg-center bg-no-repeat rounded-tl-15 rounded-br-15 mb-20 3xl:h-[30vh]'
          style={{ backgroundImage: `url(${cover2})` }}
        ></div>
        <div className='flex justify-center'>
          <h2 className='heading text-heading text-blue-dark mb-14 font-editor-bold text-center 3xl:text-4xl'>
            {translate('About', 'sections.integration.title')}
          </h2>
        </div>
        <div className='flex w-full justify-between flex-col md:flex-row'>
          <div className='mr-12 w-full md:w-1/2'>
            <p className='text-justify mb-12 font-normal 3xl:text-3xl'>
              {translate('About', 'sections.integration.p1')}
            </p>
            <p className='text-justify mb-12 md:mb-0 font-normal 3xl:text-3xl'>
              {translate('About', 'sections.integration.p2')}
            </p>
          </div>
          <div className='mr-12 w-full md:w-1/2'>
            <p className='text-justify mb-12 3xl:text-3xl'>
              {translate('About', 'sections.integration.p3')}
            </p>
            <p className='text-justify 3xl:text-3xl'>
              {translate('About', 'sections.integration.p4')}
            </p>
          </div>
        </div>
      </div>

      <div className='px-8 md:px-20 lg:px-36 xl:px-56 mt-44 3xl:w-4/5 3xl:mx-auto 3xl:px-20'>
        <div
          className='h-80 bg-cover bg-center bg-no-repeat rounded-tl-15 rounded-br-15 mb-20 3xl:h-[30vh]'
          style={{ backgroundImage: `url(${cover3})` }}
        ></div>
        <div className='flex justify-center'>
          <h2 className='heading text-heading text-blue-dark mb-14 font-editor-bold text-center 3xl:text-4xl'>
            {translate('About', 'sections.conservation.title')}
          </h2>
        </div>
        <div className='flex w-full justify-between flex-col md:flex-row'>
          <div className='mr-12 w-full md:w-1/2'>
            <p className='text-justify mb-12 3xl:text-3xl'>
              {translate('About', 'sections.conservation.p1')}
            </p>
            <p className='text-justify mb-12 md:mb-0 3xl:text-3xl'>
              {translate('About', 'sections.conservation.p2')}
            </p>
          </div>
          <div className='mr-12 w-full md:w-1/2'>
            <p className='text-justify mb-12 3xl:text-3xl'>
              {translate('About', 'sections.conservation.p3')}
            </p>
            <p className='text-justify 3xl:text-3xl'>
              {translate('About', 'sections.conservation.p4')}
            </p>
          </div>
        </div>
      </div>

      <div className='px-8 md:px-20 lg:px-36 xl:px-56 mt-44 3xl:w-4/5 3xl:mx-auto 3xl:px-20'>
        <div
          className='h-80 bg-cover bg-center bg-no-repeat rounded-tl-15 rounded-br-15 mb-20 3xl:h-[30vh]'
          style={{ backgroundImage: `url(${cover4})` }}
        ></div>
        <div className='flex justify-center'>
          <h2 className='heading text-heading text-blue-dark mb-14 font-editor-bold text-center 3xl:text-4xl'>
            {translate('About', 'sections.components.title')}
          </h2>
        </div>
        <div className='grid grid-cols-1 grid-rows-4 md:grid-cols-2 md:grid-rows-2 gap-12 w-full'>
          <div className='text-justify flex'>
            <span className='mr-4 w-9 h-9 3xl:text-2xl rounded-full bg-grey-light flex items-center justify-center text-white'>
              1
            </span>
            <div className='flex-1 3xl:text-3xl'>
              {translate('About', 'sections.components.p1')}
            </div>
          </div>
          <div className='text-justify flex'>
            <span className='mr-4 w-9 h-9 3xl:text-2xl rounded-full bg-grey-light flex items-center justify-center text-white'>
              2
            </span>
            <div className='flex-1 3xl:text-3xl'>
              {translate('About', 'sections.components.p2')}
            </div>
          </div>

          <div className='text-justify flex'>
            <span className='mr-4 w-9 h-9 3xl:text-2xl rounded-full bg-grey-light flex items-center justify-center text-white'>
              3
            </span>
            <div className='flex-1 3xl:text-3xl'>
              {translate('About', 'sections.components.p3')}
            </div>
          </div>
          <div className='text-justify flex'>
            <span className='mr-4 w-9 h-9 3xl:text-2xl rounded-full bg-grey-light flex items-center justify-center text-white'>
              4
            </span>
            <div className='flex-1 3xl:text-3xl'>
              {translate('About', 'sections.components.p4')}
            </div>
          </div>
        </div>
      </div>

      <div className='px-8 md:px-20 lg:px-36 xl:px-56 mt-44 3xl:w-4/5 3xl:mx-auto 3xl:px-20'>
        <div
          className='h-80 bg-cover bg-center bg-no-repeat rounded-tl-15 rounded-br-15 mb-20 3xl:h-[30vh]'
          style={{
            backgroundImage: `url("https://inaturalist-open-data.s3.amazonaws.com/photos/1929630/large.jpg")`,
          }}
        ></div>
        <div className='flex justify-center'>
          <h2 className='heading text-heading text-blue-dark mb-14 font-editor-bold text-center 3xl:text-4xl'>
            {translate('About', 'sections.biodiversity.title')}
          </h2>
        </div>
        <div className='flex w-full justify-between flex-col md:flex-row'>
          <div className='mr-12 w-full md:w-1/2'>
            <p className='text-justify mb-12 3xl:text-3xl'>
              {translate('About', 'sections.biodiversity.p1')}
            </p>
            <p className='text-justify mb-12 md:mb-0 3xl:text-3xl'>
              {translate('About', 'sections.biodiversity.p2')}
            </p>
          </div>
          <p className='text-justify w-full md:w-1/2 3xl:text-3xl'>
            {translate('About', 'sections.biodiversity.p3')}
          </p>
        </div>
      </div>

      <div className='px-8 md:px-20 lg:px-36 xl:px-56 mt-44 3xl:w-4/5 3xl:mx-auto 3xl:px-20'>
        <div className='flex justify-center'>
          <h2 className='heading text-heading text-blue-dark mb-14 font-editor-bold text-center 3xl:text-4xl'>
            {translate('About', 'sections.species.title')}
          </h2>
        </div>
        <div className='w-full'>
          <p className='text-justify mb-4 3xl:text-3xl'>
            {translate('About', 'sections.species.p1')}
          </p>
          <p className='text-justify mb-4 3xl:text-3xl'>
            {translate('About', 'sections.species.p2')}
          </p>
          <p className='text-justify mb-4 3xl:text-3xl'>
            {translate('About', 'sections.species.p3')}
          </p>
          <p className='text-justify mb-4 3xl:text-3xl'>
            {translate('About', 'sections.species.p4')}
          </p>
          <p className='text-justify 3xl:text-3xl'>{translate('About', 'sections.species.p5')}</p>
        </div>

        <div className='mt-14 3xl:mx-auto'>
          <h2 className='text-2xl mb-8 font-editor-bold 3xl:text-4xl'>Baja California Sur</h2>
          <div className='w-full border-b-xs pb-14'>
            <Swiper
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              slidesPerView={2}
              spaceBetween={8}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 16,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 32,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 16,
                },
                1440: {
                  slidesPerView: 6,
                  spaceBetween: 32,
                },
              }}
            >
              {speciesBySite['baja-california-sur'].map(animal => (
                <SwiperSlide className='w-full' key={`${animal.name}-slide`}>
                  <div className='w-full flex flex-col justify-center items-center text-center'>
                    <div
                      className='h-32 w-32 3xl:h-56 3xl:w-56 rounded-full shadow-lg bg-cover bg-center bg-no-repeat'
                      style={{ backgroundImage: `url(${animal?.img})` }}
                    ></div>
                    <div className='font-semibold mt-6 mb-4 3xl:text-3xl'>{animal.name}</div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>

        <div className='mt-14 3xl:mx-auto'>
          <h2 className='text-2xl mb-8 font-editor-bold 3xl:text-4xl'>Oaxaca</h2>
          <div className='w-full border-b-xs pb-14'>
            <Swiper
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              slidesPerView={2}
              spaceBetween={8}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 16,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 32,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 16,
                },
                1440: {
                  slidesPerView: 6,
                  spaceBetween: 32,
                },
              }}
            >
              {speciesBySite['costa-oaxaca'].map(animal => (
                <SwiperSlide className='w-full' key={`${animal.name}-slide`}>
                  <div className='w-full flex flex-col justify-center items-center text-center'>
                    <div
                      className='h-32 w-32 3xl:h-56 3xl:w-56 rounded-full shadow-lg bg-cover bg-center bg-no-repeat'
                      style={{ backgroundImage: `url(${animal?.img})` }}
                    ></div>
                    <div className='font-semibold mt-6 mb-4 3xl:text-3xl'>{animal.name}</div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>

        <div className='mt-14 3xl:mx-auto'>
          <h2 className='text-2xl mb-8 font-editor-bold 3xl:text-4xl'>Maya Ka´an</h2>
          <div className='w-full border-gray-light pb-14'>
            <Swiper
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              slidesPerView={2}
              spaceBetween={8}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 16,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 32,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 16,
                },
                1440: {
                  slidesPerView: 6,
                  spaceBetween: 32,
                },
              }}
            >
              {speciesBySite['maya-kaan'].map(animal => (
                <SwiperSlide className='w-full' key={`${animal.name}-slide`}>
                  <div className='w-full flex flex-col justify-center items-center text-center'>
                    <div
                      className='h-32 w-32 3xl:h-56 3xl:w-56 rounded-full shadow-lg bg-cover bg-center bg-no-repeat'
                      style={{ backgroundImage: `url(${animal?.img})` }}
                    ></div>
                    <div className='font-semibold mt-6 mb-4 3xl:text-3xl'>{animal.name}</div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>

      <div className='px-8 md:px-20 lg:px-36 xl:px-56 mt-44 3xl:w-4/5 3xl:mx-auto 3xl:px-20'>
        <div className='flex justify-center'>
          <h2 className='heading text-heading text-blue-dark mb-14 font-editor-bold 3xl:text-4xl'>
            {translate('About', 'sections.axes.title')}
          </h2>
        </div>
        <div className='flex md:basis-1/4 flex-wrap justify-around'>
          <div className='flex text-2xl mb-14 md:w-1/3 w-full 3xl:text-3xl'>
            <SimpleLogo className='path-orange w-8 mr-2' />
            <div>{translate('About', 'sections.axes.p1')}</div>
          </div>
          <div className='flex text-2xl mb-14 md:w-1/3 w-full 3xl:text-3xl '>
            <SimpleLogo className='path-orange w-8 mr-2' />
            <div>{translate('About', 'sections.axes.p2')}</div>
          </div>
          <div className='flex text-2xl mb-14 md:w-1/3 w-full 3xl:text-3xl '>
            <SimpleLogo className='path-orange w-8 mr-2' />
            <div>{translate('About', 'sections.axes.p3')}</div>
          </div>
          <div className='flex text-2xl mb-14 md:w-1/3 w-full 3xl:text-3xl '>
            <SimpleLogo className='path-orange w-8 mr-2' />
            <div>{translate('About', 'sections.axes.p4')}</div>
          </div>
          <div className='flex text-2xl mb-14 md:w-1/3 w-full 3xl:text-3xl '>
            <SimpleLogo className='path-orange w-8 mr-2' />
            <div>{translate('About', 'sections.axes.p5')}</div>
          </div>
          <div className='flex text-2xl mb-14 md:w-1/3 w-full 3xl:text-3xl '>
            <SimpleLogo className='path-orange w-8 mr-2' />
            <div>{translate('About', 'sections.axes.p6')}</div>
          </div>
          <div className='flex text-2xl mb-14 md:w-1/3 w-full 3xl:text-3xl '>
            <SimpleLogo className='path-orange w-8 mr-2' />
            <div>{translate('About', 'sections.axes.p7')}</div>
          </div>
        </div>
      </div>

      <div className='px-8 md:px-20 lg:px-36 xl:px-56 mt-44 mb-96 3xl:w-4/5 3xl:mx-auto 3xl:px-20'>
        <div className='flex justify-center'>
          <h2 className='heading text-heading text-blue-dark mb-14 font-editor-bold text-center 3xl:text-4xl'>
            {translate('About', 'sections.documents.title')}
          </h2>
        </div>
        <div className='flex md:basis-1/2 flex-wrap justify-center'>
          <a
            href='https://www.thegef.org/projects-operations/projects/9613'
            target='_blank'
            rel='noopener noreferrer'
            className='w-full md:w-1/3 flex bg-blue-dark justify-center items-center text-white py-8 px-20 rounded-lg md:mr-9 3xl:text-3xl'
          >
            <FiLink2 className='mr-2' />
            PRODOC
          </a>
          <a
            href='http://bibliodigitalibd.senado.gob.mx/bitstream/handle/123456789/5466/Presetnaci%C3%B3n%20Mtra.%20Tiahoga%20Ruge%20%28KUXATUR%29.pdf?sequence=4&isAllowed=y'
            target='_blank'
            rel='noopener noreferrer'
            className='w-full md:w-1/3 flex bg-blue-dark justify-center items-center text-white py-8 px-20 rounded-lg mt-9 md:mt-0 3xl:text-3xl'
          >
            <FiLink2 className='mr-2' />
            {translate('About', 'sections.documents.presentation')}
          </a>
        </div>
      </div>
    </React.Fragment>
  );
}
