import React, { useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { Gallery, Item } from 'react-photoswipe-gallery';
import { useMediaQuery } from 'react-haiku';

import { LocaleContext } from '../components/common/LocaleContext';
import { useTranslation } from '../hooks';
import HeroHome from '../statics/images/hero_home.png';
import mobilePostal from '../statics/images/postal_mobile.png';
import kantemo from '../statics/images/maya/maya6.png';
import earth from '../statics/images/Earth.png';
import earthMobile from '../statics/images/earth_mobile.png';
import {
  Location,
  MexicoAlive,
  MexicoVivoBlanco,
  IsologoAlive,
  IsologoVivo,
  Image,
  Indicator,
  ChevronDown,
} from '../statics/icons';
import { HomeCard, Suscribe } from '../components';
import data from '../data';

export default function Home() {
  const { landing, sites } = data;
  const viewRef = useRef(null);
  const { locale } = useContext(LocaleContext);
  const { translate } = useTranslation({ locale });

  const isMobile = useMediaQuery('(max-width: 500px)');
  const md = useMediaQuery('(min-width: 768px)');

  const scrollIntoView = () => {
    viewRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <React.Fragment>
      <div
        className='3xl:h-[95vh] px-8 rounded-b-full md:rounded-none mb-16 h-screen md:h-hero w-full bg-cover bg-center bg-no-repeat relative xl:mb-80 text-center flex flex-col justify-between items-center md:flex-row md:justify-center md:items-start pt-48 pb-12 md:pb-0'
        style={{ backgroundImage: `url(${HeroHome})` }}
      >
        {locale === 'es' ? (
          <MexicoVivoBlanco className='w-full md:w-2/3 xl:w-1/3 path-white' />
        ) : (
          <MexicoAlive className='w-full md:w-2/3 xl:w-1/3 path-white' />
        )}
        <button className='border-none bg-transparent' onClick={scrollIntoView}>
          <ChevronDown className='md:hidden animate-bounce' />
        </button>
      </div>

      <div
        ref={viewRef}
        className='bg-white md:bg-transparent rounded-b-16 w-full flex flex-col-reverse xl:flex-row xl:pl-28 3xl:pl-[18rem] xl:absolute -bottom-16 justify-center items-center'
      >
        <img className='xl:mr-32 pt-10 pb-16 3xl:w-1/5' src={mobilePostal} alt='postal1' />
        <div className='3xl:h-[45vh] pt-14 3xl:pb-14 px-8 3xl:px-16 3xl:pt-28 3xl:w-3/4 flex flex-col xl:flex-row flex-auto rounded-tl-extralg bg-white justify-center items-center'>
          <div className='lg:w-1/2 text-center'>
            <h1 className='heading text-heading mb-8 text-blue-dark font-editor-bold 3xl:text-6xl'>
              {translate('Home', 'hero.title')}
            </h1>
            <p className='font-light leading-6 text-justify 3xl:text-3xl'>
              {translate('Home', 'hero.text')}
            </p>
          </div>
          <div className='mt-16 lg:w-1/2 text-center text-grey-light flex flex-col items-center justify-center'>
            <div className='font-light mb-4 3xl:text-3xl'>
              <span className='font-semibold'>Kuxatur</span> {translate('Home', 'hero.origin')}
            </div>
            <div className='font-bold text-2xl font-editor-bold 3xl:text-3xl'>
              kuxaʼan (maya) = “{translate('Home', 'hero.alive')}”
            </div>
            <div className='text-2xl font-editor-bold 3xl:text-3xl'>+</div>
            <div className='font-bold text-2xl font-editor-bold 3xl:text-3xl'>
              tur = {translate('Home', 'hero.tourism')}
            </div>
          </div>
        </div>
      </div>

      <div className='lg:px-20 w-full z-negative-xs -top-28 md:top-0 md:z-0 3xl:w-4/5 3xl:mx-auto'>
        <React.Fragment>
          <div
            className='lg:rounded-2xl lg:h-180 relative mb-12 md:mb-0 before:hidden xl:before:block video-border'
            style={{ padding: '56.25% 0 0 0', position: 'relative' }}
          >
            <iframe
              title='kuxatur trailer'
              className='lg:rounded-2xl'
              src='https://player.vimeo.com/video/672239650?h=b6c91ef418&title=0&byline=0&portrait=0'
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              frameBorder='0'
              allow='autoplay'
            ></iframe>
          </div>
          <script src='https://player.vimeo.com/api/player.js'></script>
        </React.Fragment>
      </div>

      <div className='w-full flex justify-center items-center pt-12 flex-col-reverse md:flex-col pb-0 md:pb-40 3xl:w-4/5 3xl:mx-auto'>
        <div className='flex flex-col justify-center items-center w-full text-center'>
          <div className='flex flex-col justify-center items-center w-full text-center md:w-2/5 px-8 md:px-0 mt-8'>
            <Location />
            <h1 className='heading-down text-heading mb-8 text-blue-dark mt-4 font-editor-bold 3xl:text-6xl'>
              {translate('Home', 'map.title')}
            </h1>
          </div>

          <div className='md:px-12 lg:h-166 w-full lg:w-166 mt-14 md:mt-12 3xl:mt-28 mb-14 md:mb-32 3xl:mb-40 relative flex justify-center items-center 3xl:w-5/12 3xl:h-auto'>
            <div className='w-full hidden md:block relative'>
              <img className='hidden md:block w-full' src={earth} alt='earth' />
              <div className='absolute bottom-[55%] left-[27%]'>
                <Indicator />
                <Link to='/destino/baja-california-sur'>
                  <span className='underline font-editor-bold tooltipBaja-w absolute text-xl md:text-2xl 3xl:text-4xl bg-neutral-900/90 text-white px-4 py-1 rounded-full w-max -left-[400%] -bottom-[200%]'>
                    Baja California Sur
                  </span>
                </Link>
              </div>
              <div className='absolute bottom-[38%] right-[36%]'>
                <Indicator />
                <Link to='/destino/costa-oaxaca'>
                  <span className='underline font-editor-bold tooltipOax-w absolute text-xl md:text-2xl 3xl:text-4xl bg-neutral-900/90 text-white px-4 py-1 rounded-full w-max -bottom-[230%] -left-[400%]'>
                    Costa Oaxaca
                  </span>
                </Link>
              </div>
              <div className='absolute bottom-[48%] right-[22%]'>
                <Indicator />
                <Link to='/destino/maya-kaan'>
                  <span className='underline font-editor-bold tooltipMaya-w absolute text-xl md:text-2xl 3xl:text-4xl bg-neutral-900/90 text-white px-4 py-1 rounded-full w-max -left-[400%] bottom-[150%]'>
                    Maya Ka’an
                  </span>
                </Link>
              </div>
            </div>

            <div className='w-full md:hidden relative'>
              <img className='w-full relative' src={earthMobile} alt='earth-mobile' />
              <div className='absolute bottom-[36%] left-[32%]'>
                <Indicator />
                <Link to='/destino/baja-california-sur'>
                  <span className='underline font-editor-bold tooltipBaja absolute text-xl bg-neutral-900/90 text-white px-4 py-1 rounded-full w-max -left-[400%] -bottom-[200%]'>
                    Baja California Sur
                  </span>
                </Link>
              </div>
              <div className='absolute bottom-[31%] right-[23%]'>
                <Indicator />
                <Link to='/destino/costa-oaxaca'>
                  <span className='underline font-editor-bold tooltipOax absolute text-xl bg-neutral-900/90 text-white px-4 py-1 rounded-full w-max -bottom-[280%] -left-[400%]'>
                    Costa Oaxaca
                  </span>
                </Link>
              </div>
              <div className='absolute bottom-[40%] right-[17%]'>
                <Indicator />
                <Link to='/destino/maya-kaan'>
                  <span className='underline font-editor-bold tooltipMaya absolute text-xl bg-neutral-900/90 text-white px-4 py-1 rounded-full w-max -left-[400%] bottom-[150%]'>
                    Maya Ka’an
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='w-full flex flex-col lg:flex-row justify-around items-center px-8 lg:px-60'>
          <div className='text-center w-full md:w-1/2'>
            <h1 className='heading text-heading text-blue-dark mb-8 font-editor-bold 3xl:text-6xl'>
              {translate('Home', 'map.vision')}
            </h1>
            <p className='font-normal 3xl:text-3xl'>{translate('Home', 'map.text')}</p>
          </div>

          {locale === 'es' ? (
            <IsologoVivo className='3xl:max-w-lg w-1/2 md:w-1/3 path-blue-dark md:block mt-8 md:mt-20 lg:mt-0 mb-24 md:mb-0' />
          ) : (
            <IsologoAlive className='3xl:max-w-lg w-1/2 md:w-1/3 path-blue-dark md:block mt-8 md:mt-20 lg:mt-0 mb-24 md:mb-0' />
          )}
        </div>
      </div>

      <div className='px-8 lg:px-20 w-full 3xl:w-4/5 3xl:mx-auto'>
        {/* Gallery section */}
        {Object.values(sites).map(site => {
          const { images } = site;
          const [img1, img2, img3, img4, ...remainImages] = images;
          return (
            <div className='pb-20 last:pb-0' key={`${site.path}`}>
              <div className='flex justify-between items-center mb-8'>
                <div className='font-editor-bold font-bold text-2xl lg:text-8 text-blue-dark md:border-3 md:border-blue-dark rounded-20 md:py-4 md:px-8 3xl:text-6xl 3xl:py-8 3xl:px-16'>
                  {site.name}
                </div>
                <Link
                  to={site.path}
                  className='w-1/3 md:w-auto text-center text-lg font-normal md:font-semibold 3xl:text-4xl'
                >
                  <span className='underline'>{translate('Common', 'cta.more')}</span> +
                </Link>
              </div>
              {md && (
                <Gallery>
                  <div className='grid gap-3 grid-cols-8 grid-rows-2 h-96 3xl:h-[45vh]'>
                    <Item
                      original={img1.src}
                      thumbnail={img1.src}
                      width={img1.width}
                      height={img1.height}
                    >
                      {({ ref, open }) => (
                        <div
                          ref={ref}
                          onClick={open}
                          className='col-span-4 row-span-2 bg-cover bg-center bg-no-repeat rounded-l-4xl cursor-pointer hover:scale-[1.02] transition duration-300 ease-in-out'
                          style={{ backgroundImage: `url(${img1.src})` }}
                        ></div>
                      )}
                    </Item>

                    <Item
                      original={img2.src}
                      thumbnail={img2.src}
                      width={img2.width}
                      height={img2.height}
                    >
                      {({ ref, open }) => (
                        <div
                          ref={ref}
                          onClick={open}
                          className='col-span-2 row-span-2 bg-cover bg-center bg-no-repeat cursor-pointer hover:scale-[1.02] transition duration-300 ease-in-out'
                          style={{ backgroundImage: `url(${img2.src})` }}
                        ></div>
                      )}
                    </Item>
                    <Item
                      original={img3.src}
                      thumbnail={img3.src}
                      width={img3.width}
                      height={img3.height}
                    >
                      {({ ref, open }) => (
                        <div
                          ref={ref}
                          onClick={open}
                          className='col-span-2 row-span-1 bg-cover bg-center bg-no-repeat rounded-r-4xl cursor-pointer hover:scale-[1.02] transition duration-300 ease-in-out'
                          style={{ backgroundImage: `url(${img3.src})` }}
                        ></div>
                      )}
                    </Item>
                    <Item
                      original={img4.src}
                      thumbnail={img4.src}
                      width={img4.width}
                      height={img4.height}
                    >
                      {({ ref, open }) => (
                        <div
                          ref={ref}
                          onClick={open}
                          className='col-span-2 row-span-1 bg-cover bg-center bg-no-repeat rounded-r-4xl relative overflow-hidden cursor-pointer hover:scale-[1.02] transition duration-300 ease-in-out'
                          style={{ backgroundImage: `url(${img4.src})` }}
                        >
                          <div className='relative w-full h-full flex justify-center items-center text-white flex-col'>
                            <div className='absolute w-full h-full bg-neutral-900 opacity-50'></div>
                            <div className='relative z-10 flex justify-center items-center flex-col'>
                              <Image />
                              <span>{translate('Common', 'cta.gallery')}</span>
                            </div>
                          </div>
                        </div>
                      )}
                    </Item>

                    {/* Hidden gallery items */}
                    {remainImages.map((img, index) => (
                      <Item
                        width={img.width}
                        height={img.height}
                        original={img.src}
                        className='hidden'
                        key={`${site.name}-img-${index + 4}`}
                      >
                        {({ ref, open }) => (
                          <img
                            className='hidden'
                            onClick={open}
                            ref={ref}
                            src={img.src}
                            alt={`${site.name}-img-${index + 4}`}
                          />
                        )}
                      </Item>
                    ))}
                    {/* END Hidden gallery items */}
                  </div>
                </Gallery>
              )}

              {isMobile && (
                <Swiper
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                  slidesPerView={1.5}
                  spaceBetween={16}
                  breakpoints={{
                    320: {
                      slidesPerView: 1.3,
                      spaceBetween: 32,
                    },
                    375: {
                      slidesPerView: 1.5,
                      spaceBetween: 16,
                    },
                    425: {
                      slidesPerView: 1.8,
                      spaceBetween: 32,
                    },
                  }}
                >
                  {images.map(img => (
                    <SwiperSlide className='w-full' key={`${img.src}-slide`}>
                      <HomeCard img={img.src} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
            </div>
          );
        })}
        {/* Gallery section */}
      </div>

      <div className='px-8 lg:px-20 w-full my-40 flex flex-col justify-center items-center 3xl:w-4/5 3xl:mx-auto'>
        <div
          className='h-52 w-full bg-cover bg-center bg-no-repeat rounded-xl md:bg-[center_top_-15rem] 3xl:h-[25vh]'
          style={{ backgroundImage: `url(${kantemo})` }}
        ></div>
        <h1 className='heading text-heading text-blue-dark mb-8 mt-16 text-center font-editor-bold 3xl:text-6xl'>
          {translate('Home', 'objective.title')}
        </h1>
        <p className='lg:w-1/2 text-center font-normal 3xl:text-3xl'>
          {translate('Home', 'objective.text')}
        </p>
        <Link
          className='bg-blue-dark mt-8 text-white py-4 px-8 rounded-lg text-center 3xl:text-3xl'
          to='/nosotros'
        >
          {translate('Common', 'cta.knowMore')}
        </Link>
      </div>

      <div className='px-8 lg:px-20 mb-40 lg:mb-40 w-full flex justify-center flex-col items-center 3xl:w-4/5 3xl:mx-auto'>
        <h1 className='heading text-heading text-blue-dark mb-12 mt-16 font-editor-bold 3xl:text-6xl'>
          {translate('Home', 'us.title')}
        </h1>
        <div className='w-full'>
          <Swiper
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            slidesPerView={1.5}
            spaceBetween={32}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 16,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 32,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 32,
              },
            }}
          >
            {landing.partners.map(partner => (
              <SwiperSlide className='w-full' key={`${partner.name}-slide`}>
                <div className='w-full flex flex-col justify-center items-center text-center'>
                  <div className='h-36 w-36 3xl:h-56 3xl:w-56 flex justify-center items-center rounded-full shadow-lg'>
                    <img src={partner.img} alt={partner.alt} />
                  </div>
                  <div className='font-semibold mt-6 mb-4 3xl:text-4xl'>{partner.name}</div>
                  <p className='font-normal line-clamp-16 mb-4 text-justify 3xl:text-3xl'>
                    {partner.description[locale]}
                  </p>
                  <a
                    href={partner.website}
                    target='_blank'
                    rel='noreferrer'
                    className='3xl:text-3xl'
                  >
                    {translate('Common', 'cta.more')}
                  </a>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div className='px-8 lg:px-20 my-40 w-full flex justify-center flex-col items-center'>
        <Suscribe />
      </div>
    </React.Fragment>
  );
}
