export default function BCS({ className }) {
  return (
    <svg
      version='1.1'
      id='Layer_1'
      x='0px'
      y='0px'
      viewBox='200 300 1510 480'
      className={className}
    >
      <g>
        <path
          className='st0'
          d='M803.3,602.55c-3.88-2.81-9.3-1.94-12.11,1.95c-25.04,34.65-56.28,57.92-77.74,57.92
		c-15.18,0-24.88-4.83-29.64-14.77c-13.25-27.64,11.13-105.22,68.54-176.66c25.49-31.71,45.64-40.31,53.58-34.91
		c6.99,4.77,9.3,25.81-7.92,55.79c-2.38,4.15-0.95,9.45,3.2,11.83c4.15,2.39,9.45,0.95,11.84-3.2
		c19.57-34.07,20.67-66.45,2.66-78.75c-18.61-12.7-47.36,1.63-76.88,38.37c-42.69,53.12-92.84,148.75-70.66,195.01
		c5.38,11.23,17.6,24.62,45.28,24.62c27.6,0,62.77-24.95,91.8-65.11C808.05,610.77,807.18,605.35,803.3,602.55z'
        />
        <path
          className='st0'
          d='M885.23,475.05c-4.29-1.47-8.98,0.75-10.51,5.02c-1.27,3.54-2.58,7.06-3.92,10.53
		c-0.6-0.36-1.25-0.66-1.95-0.86c-13.49-3.98-27.26,1.18-38.83,14.5c-15.2,17.51-26.21,50.22-18.2,67.77
		c3.23,7.08,9.45,11.15,17.04,11.15c11.23,0,23.05-14.61,33.72-33.82c0.3,10.19,2.06,19.76,6.16,27.28c2.19,4,7.22,5.48,11.23,3.3
		c4.01-2.19,5.49-7.22,3.3-11.24c-10.61-19.43,1.52-66.63,7.09-83.18C891.8,481.21,889.52,476.55,885.23,475.05z M828.86,566.61
		c-0.87,0-1.31,0-1.98-1.46c-3.78-8.29,1.73-34,15.65-50.04c4.2-4.84,10.39-10.08,17.5-10.08c1.35,0,2.73,0.19,4.14,0.6
		c0.18,0.05,0.36,0.1,0.54,0.14C850.73,539.02,835.58,565.28,828.86,566.61z'
        />
        <path
          className='st0'
          d='M1346.76,413.71c-4.29-1.47-8.98,0.75-10.51,5.02c-1.27,3.54-2.58,7.06-3.92,10.53
		c-0.6-0.36-1.25-0.66-1.95-0.86c-13.49-3.98-27.26,1.18-38.83,14.5c-8.8,10.14-16.17,25.37-19.28,39.81
		c-0.42,0.46-0.79,0.97-1.11,1.54l-2.14,3.83c-4.92,8.83-11.22,20.14-17.64,27.84c-0.07-16.69,5.38-52.99,12-84.09
		c1.01-4.73-2.01-9.39-6.74-10.4c-4.74-1-9.39,2.01-10.39,6.74c-21.04,98.78-9.31,106.21-4.89,109.01c1.14,0.72,3.3,1.79,6.15,1.79
		c2.45,0,5.4-0.78,8.63-3.25c6.83-5.2,13.03-13.64,18.57-22.53c3.46,5.52,9.04,8.63,15.69,8.63c11.23,0,23.05-14.61,33.72-33.81
		c0.3,10.19,2.06,19.76,6.16,27.28c2.19,4,7.22,5.48,11.23,3.3c4.01-2.19,5.49-7.22,3.3-11.24c-10.61-19.43,1.52-66.63,7.09-83.18
		C1353.33,419.87,1351.04,415.2,1346.76,413.71z M1290.39,505.26c-0.87,0-1.31,0-1.98-1.46c-3.78-8.29,1.73-34,15.65-50.04
		c4.2-4.84,10.39-10.08,17.5-10.08c1.35,0,2.73,0.19,4.14,0.6c0.18,0.05,0.36,0.1,0.54,0.14
		C1312.26,477.68,1297.11,503.93,1290.39,505.26z'
        />
        <path
          className='st0'
          d='M514.7,502.39c8.32,0,15.07-6.74,15.07-15.07c0-8.32-6.74-15.07-15.07-15.07s-15.07,6.75-15.07,15.07
		C499.64,495.65,506.38,502.39,514.7,502.39z'
        />
        <path
          className='st0'
          d='M957.13,430.93c8.32,0,15.07-6.74,15.07-15.07s-6.74-15.07-15.07-15.07s-15.07,6.75-15.07,15.07
		S948.81,430.93,957.13,430.93z'
        />
        <path
          className='st0'
          d='M1263.13,397.49c8.32,0,15.07-6.74,15.07-15.07c0-8.32-6.74-15.07-15.07-15.07s-15.07,6.74-15.07,15.07
		C1248.06,390.75,1254.81,397.49,1263.13,397.49z'
        />
        <path
          className='st0'
          d='M621.65,510.79c-4.27-1.47-8.98,0.74-10.51,5.02c-1.27,3.54-2.58,7.05-3.91,10.53
		c-0.6-0.36-1.25-0.66-1.95-0.86c-11-3.25-22.18-0.31-32.21,8.07c-0.7,0.32-1.38,0.71-1.99,1.23
		c-16.65,14.44-32.3,30.85-46.62,48.14c1.68-25.58,1.78-43.91,1.78-46.21c0.01-3.57-2.27-6.74-5.65-7.87
		c-3.38-1.13-7.11,0.03-9.25,2.88c-2.58,3.44-6.34,11.26-13.34,25.96c-8.37,17.57-19.5,40.98-30.22,58.37
		c-5.82-21.92,4.55-61.82,9.57-76.76c1.45-4.3-0.84-8.97-5.13-10.45c-4.29-1.5-8.98,0.74-10.51,5.02
		c-1.27,3.54-2.58,7.05-3.92,10.52c-0.6-0.36-1.25-0.65-1.95-0.86c-9.29-2.74-18.74-1.03-27.51,4.61c-0.23,0.05-0.45,0.06-0.68,0.13
		c-17.06,5.1-34.85,12.08-52.6,20.44c-5.08-4.52-11.21-7-17.56-8.17c17.66-13.45,36.81-33.74,42.91-61.19
		c4.29-19.33,1.16-32.68-9.32-39.71c-20.02-13.41-63.71,3.16-129.87,49.28c-3.75,2.61-4.67,7.77-2.06,11.53
		c2.61,3.75,7.77,4.66,11.53,2.06c86.06-59.98,107.73-51.43,111.19-49.11c5.56,3.73,3.87,15.61,2.37,22.36
		c-10.08,45.38-66.44,69.32-67.01,69.56c-4.16,1.73-6.17,6.48-4.52,10.67c1.65,4.19,6.35,6.29,10.58,4.73
		c6.11-2.27,22.64-6.49,33.98-3.19c-23.24,12.14-45.95,26.34-66.35,41.4c15.72-67.42,51.03-116.52,51.52-117.19
		c2.69-3.69,1.89-8.87-1.8-11.57c-3.7-2.71-8.87-1.89-11.56,1.79c-2.31,3.16-45.21,62.69-58.36,142.8
		c-41.47,34.59-68.37,71.36-60.91,96.91c4.51,15.43,17.38,24.68,35.3,25.39c0.77,0.03,1.54,0.05,2.32,0.05
		c41.55,0,101.77-42.91,128.88-114.18c10.03-26.39,12.64-46.33,7.82-60.39c7.56-3.48,15.2-6.71,22.87-9.67
		c-9.37,17.52-14.28,39.6-8.19,52.93c3.23,7.08,9.44,11.15,17.04,11.15c11.23,0,23.05-14.62,33.72-33.81
		c0.3,10.19,2.06,19.76,6.16,27.28c2.14,3.92,6.06,6.38,10.47,6.57c4.61,0.12,9.1-2.13,11.73-6.08
		c10.56-15.85,21.56-38.02,30.48-56.61c-0.6,10.13-1.43,21.13-2.54,32.56c-50.76,69.66-78.74,145.4-63.32,163.43
		c1.98,2.32,5.31,4.88,10.37,4.88c4.73-0.01,10.98-2.25,19.02-9.03c31.15-26.27,44.43-97.72,50.09-154.02
		c7.57-10.11,15.81-20.18,24.67-30.06c-1.79,9.67-1.59,18.84,1.54,25.69c3.23,7.08,9.44,11.15,17.04,11.15
		c11.23,0,23.05-14.62,33.72-33.81c0.3,10.19,2.05,19.76,6.16,27.27c2.19,4.01,7.22,5.49,11.23,3.3c4.01-2.19,5.49-7.22,3.3-11.23
		c-10.61-19.43,1.52-66.63,7.09-83.19C628.23,516.95,625.94,512.29,621.65,510.79z M360.86,637.05
		c-26.41,69.44-83.79,104.77-115.06,103.48c-10.82-0.43-17.57-4.97-20.06-13.49c-4.43-15.14,12.77-40.85,42.09-67.9
		c-1.18,15.06-1.22,30.65,0.37,46.6c0.43,4.27,4.03,7.46,8.23,7.46c0.28,0,0.55-0.01,0.83-0.04c4.55-0.46,7.87-4.51,7.41-9.06
		c-2.1-21.01-1.15-41.51,1.69-60.92c23.59-19.05,52.2-37.86,82.61-53.29C371.46,600.13,368.73,616.36,360.86,637.05z M415.86,620.4
		c-0.87,0-1.31,0-1.98-1.46c-3.78-8.28,1.73-34,15.64-50.04c4.2-4.85,10.39-10.09,17.51-10.09c1.35,0,2.73,0.19,4.14,0.6
		c0.18,0.05,0.36,0.1,0.54,0.14C437.73,592.81,422.58,619.07,415.86,620.4z M461.28,753.48c-3.19,2.69-5.34,3.96-6.64,4.56
		c-2.93-12.44,12.49-61.84,47-116.32C494.7,688.92,482.35,735.72,461.28,753.48z M565.29,602.35c-0.87,0-1.31,0-1.98-1.46
		c-3.78-8.29,1.73-34,15.65-50.05c4.2-4.84,10.39-10.08,17.5-10.08c1.35,0,2.73,0.19,4.14,0.6c0.18,0.05,0.36,0.1,0.54,0.14
		C587.16,574.76,572.01,601.01,565.29,602.35z'
        />
        <path
          className='st0'
          d='M1706.89,379.59c-9.22-4.7-18.87-5.24-28.68-1.64c-11.29,4.15-21.4,13.66-29.78,24.23l4.16-20.55
		c0.84-4.15-1.58-8.26-5.62-9.54c-4.04-1.27-8.39,0.7-10.08,4.58c-18.24,41.69-41.86,88.71-53.23,103.12
		c-0.39-13.09,6.29-45.07,16.53-77.57c0.3-0.94,0.48-1.5,0.52-1.64c1.34-4.35-1.08-8.98-5.43-10.34c-4.34-1.36-8.98,1.05-10.37,5.39
		c-0.39,1.21-0.77,2.41-1.14,3.59c-11.56,35.79-35.01,85.6-51.72,85.6c-2.22,0-2.55-0.55-2.69-0.79
		c-4.41-7.47,6.63-37.9,19.71-61.65c1.69-3.07,1.27-6.87-1.06-9.49c-2.33-2.62-6.06-3.48-9.3-2.17
		c-15.47,6.28-29.64,12.9-42.61,19.76c-16.36-15.22-40.14-6.96-56.76-1.2c-7.81,2.7-19.6,6.8-22.59,4.69
		c-10.33-7.32-2.02-27.98,9.19-44.88c28.26-42.59,79.32-76.95,97.56-65.61c3.88,2.42,8.99,1.23,11.4-2.66
		c2.41-3.89,1.22-8.99-2.67-11.4c-14.72-9.12-37.05-4.56-62.91,12.9c-21.49,14.51-42.86,36.05-57.18,57.62
		c-20.39,30.72-22.2,55.34-4.97,67.54c9.93,7.04,23.37,2.39,37.59-2.56c15.2-5.27,26.64-8.61,35.02-5.32
		c-82.31,48.63-109.34,106.33-111.55,136.94c-1.19,16.46,4.04,29.37,14.36,35.41c2.99,1.75,7.63,3.59,13.95,3.59
		c7.24,0,16.68-2.41,28.35-10.12c70.55-46.57,89.04-136.36,80.62-161.17c5.98-3.14,12.25-6.24,18.79-9.26
		c-8.49,19.04-16.93,44.37-9.21,57.45c3.4,5.76,9.42,8.93,16.94,8.93c11.95,0,23.64-8.71,35.42-26.47
		c-0.31,11.35,1.65,16.94,3.67,19.69c2.54,3.47,6.26,5.38,10.49,5.38c4.09,0,13.42,0,40.97-53.74l-7.89,38.93
		c-0.91,4.48,1.99,8.85,6.47,9.76c4.51,0.94,8.85-1.99,9.76-6.47l7.46-36.83c0.14-0.25,0.33-0.45,0.44-0.72
		c5.62-13.43,24.68-49.93,45.08-57.42c5.45-2,10.36-1.73,15.44,0.86c4.08,2.08,9.06,0.45,11.13-3.62
		C1712.58,386.65,1710.96,381.67,1706.89,379.59z M1415.8,591.62c-10.51,6.93-19.56,9.14-24.82,6.06
		c-4.6-2.69-6.93-10.15-6.22-19.93c1.86-25.71,27.36-79.52,105.96-125.29C1494.11,471.84,1477.71,550.75,1415.8,591.62z'
        />
        <path
          className='st0'
          d='M931.62,389.46c0.53-4.81-2.93-9.14-7.74-9.67c-4.81-0.54-9.14,2.93-9.67,7.74
		c-0.85,7.63-20.76,187.33-19.96,221.77c0.11,4.77,4.01,8.55,8.75,8.55c0.07,0,0.14,0,0.21,0c4.83-0.12,8.67-4.12,8.55-8.96
		C910.99,575.61,931.42,391.31,931.62,389.46z'
        />
        <path
          className='st0'
          d='M1156.46,458.46c0.51-4.81-2.97-9.13-7.78-9.64c-10.08-1.07-19.22,1.83-27.18,8.64
		c-1.55,1.33-3.03,2.79-4.43,4.36l2.67-26.13c0.48-4.67-2.82-8.9-7.47-9.56c-4.65-0.67-9,2.46-9.85,7.09
		c-1.64,8.91-7.66,29.59-18.05,42c-0.03-0.32-0.02-0.64-0.06-0.96c-1.2-9.7-5.11-16.2-11.53-18.64c-0.94-0.36-1.87-0.56-2.81-0.8
		c-0.02-0.08-0.01-0.15-0.03-0.23c-1.16-4.7-5.93-7.55-10.6-6.41l-56.92,14.04c12.01-44.13,29.74-81.74,45.6-83.32
		c5.92-0.58,10.15,0.53,12.96,3.42c5.84,6.01,5.66,18.79,5.09,23.22c-0.63,4.79,2.73,9.18,7.52,9.82c4.81,0.68,9.2-2.72,9.85-7.52
		c0.33-2.47,2.88-24.52-9.83-37.67c-4.6-4.75-13.07-10.12-27.32-8.71c-25.74,2.57-44.1,42.94-54.98,76.36
		c-3.05,9.36-5.82,19.19-8.29,29.18l-8.84,2.18c-4.7,1.16-7.56,5.91-6.41,10.6c0.98,3.99,4.56,6.66,8.5,6.66
		c0.7,0,1.4-0.09,2.11-0.26l0.35-0.09c-1.26,6.2-2.39,12.39-3.38,18.5c-4.36,9.92-17.07,35.06-30.37,49.12
		c-0.17-16.47,5.31-53.17,11.99-84.56c1.01-4.73-2.01-9.39-6.74-10.4c-4.74-1-9.38,2.02-10.39,6.74
		c-21.04,98.78-9.31,106.21-4.89,109.01c1.14,0.72,3.3,1.79,6.15,1.79c2.45,0,5.4-0.78,8.63-3.25c7.58-5.78,14.82-14.72,21.08-24.02
		c-0.07,2.28-0.11,4.52-0.11,6.7c0,64.68,15.35,97.22,16,98.58c1.51,3.12,4.63,4.94,7.88,4.94c1.27,0,2.56-0.28,3.78-0.86
		c4.35-2.08,6.19-7.31,4.13-11.67c-0.14-0.3-14.27-30.78-14.27-90.99c0-21.91,3.62-48.48,9.43-74.3l29.45-7.27
		c-9.41,16.48-13.65,37.5-12.13,53.31c1.71,17.82,10.78,28.45,24.26,28.45c22.43,0,38.2-29.42,43.57-57.19
		c5.35-2.7,10.01-6.58,14.03-11.16l-4.03,39.47c-1.59,10.95-2.08,19.28-2.19,21.59c-0.23,4.74,3.36,8.8,8.09,9.16
		c0.22,0.01,0.44,0.02,0.67,0.02c4.45,0,8.25-3.37,8.7-7.87l1.97-19.28c3.01-20.76,9.77-48.71,22.86-59.91
		c4.26-3.64,8.68-5.08,13.92-4.52C1151.64,466.73,1155.95,463.27,1156.46,458.46z M1065.97,471.59c1.07,1.76,1.58,6,1.31,11.65
		c-4.9,1.57-7.66,1.21-8.62,0.43c-1.22-0.98-1.5-5.09,0.61-8.75c1.67-2.91,3.53-3.62,5.02-3.62
		C1064.93,471.31,1065.51,471.44,1065.97,471.59z M1058.02,519.4c-5.74,12.01-12.76,19.18-18.79,19.18c-5.21,0-6.63-8.93-7-12.77
		c-1.22-12.64,2.31-28.23,8.64-40.43c0.95,4.69,3.28,8.88,6.91,11.8c3.76,3.03,9.1,5.1,16.66,4.3
		C1062.94,507.34,1060.84,513.49,1058.02,519.4z'
        />
        <path
          className='st0'
          d='M1223.46,471.6c0.76-20.13,1.18-31.22-6.82-36.28c-2.27-1.44-7.06-3.32-13.66,0.12
		c-9.82,5.11-19.54,17.69-29.5,38.25l3.91-40.02c0.47-4.81-3.05-9.1-7.87-9.57c-4.81-0.49-9.1,3.05-9.57,7.87l-10.39,106.29
		c-0.44,4.51,2.64,8.61,7.09,9.46c4.44,0.85,8.82-1.85,10.05-6.21c7.8-27.48,24.51-71.94,39.58-86.8c0.1,4.63-0.14,10.84-0.34,16.25
		c-0.91,24.17-2.3,60.71,12.64,90.58c1.53,3.07,4.63,4.85,7.84,4.85c1.32,0,2.65-0.3,3.91-0.93c4.33-2.16,6.08-7.43,3.92-11.75
		C1221.33,527.83,1222.57,495.32,1223.46,471.6z'
        />
      </g>
    </svg>
  );
}
