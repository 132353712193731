import { BCS as BCSCalligraphy } from '../../statics/icons';

import bajaCover from '../../statics/images/baja/baja_cover.jpg';
import bajaPoligono from '../../statics/images/baja/baja_poligono.png';
import islaCover from '../../statics/images/baja/isla.png';
import setue from '../../statics/images/baja/SETUE.png';
import sepuimm from '../../statics/images/baja/SEPUIMM.jpg';
import ayuntamientoLaPaz from '../../statics/images/baja/ayuntamiento_la_paz.png';
import ayuntamientoLosCabos from '../../statics/images/baja/ayuntamiento_los_Cabos.jpg';
import espirituSanto from '../../statics/images/baja/espiritu_santo.jpg';
import lalaguna from '../../statics/images/baja/LaLaguna.png';
import caboPulmo from '../../statics/images/baja/cabo_pulmo.png';
import conabio from '../../statics/images/baja/Conabio.png';
import caboSanLucas from '../../statics/images/baja/cabo_san_lucas.png';
import uabcs from '../../statics/images/baja/logo_uabcs.png';
import baja1 from '../../statics/images/baja/baja1.jpg';
import baja2 from '../../statics/images/baja/baja2.jpg';
import baja3 from '../../statics/images/baja/baja3.jpg';
import baja4 from '../../statics/images/baja/baja4.jpg';
import baja5 from '../../statics/images/baja/baja5.jpg';
import baja6 from '../../statics/images/baja/baja6.jpg';
import baja7 from '../../statics/images/baja/baja7.jpg';
import baja8 from '../../statics/images/baja/baja8.png';
import baja9 from '../../statics/images/baja/baja9.jpg';
import baja10 from '../../statics/images/baja/baja10.jpg';
import baja11 from '../../statics/images/baja/baja11.jpg';

const bajaSur = {
  name: 'Baja California Sur',
  description:
    'Baja California Sur (BCS) es un destino turístico comprometido con el cuidado de sus ecosistemas y su biodiversidad, muestra de esto son sus 14 áreas naturales protegidas (ANP) que representan el 42 por ciento del total del territorio sudcaliforniano. En lo específico, la región sur del estado, en donde se encuentran destinos turísticos de talla internacional como Los Cabos y La Paz, es en donde el turista encuentra una amplia diversificación de actividades relacionadas al ecoturismo, el turismo rural, de aventura y cultural; así mismo es en esta región en la que hay una mayor diversidad de ecosistemas con características únicas como manglares, dunas, el único sistema arrecifal rocoso del Golfo de California, selva baja caducifolia, bosque de pino-encino, oasis e islas; todos estos forman parte del Circuito de Biodiversidad en estas 7 ANP (6 de carácter federal y 1 estatal). Los principales atractivos turísticos en estos ecosistemas son observación de ballena jorobada, nado con lobo marino, nado con tiburón ballena, pesca deportiva, observación de aves, buceo y snorkel en el sistema arrecifal, entre otros. En el aspecto cultural, se tienen los pueblos históricos, comunidades pesqueras, ranchos sudcalifornianos y la ruta de las misiones.',
  cover: bajaCover,
  calligraphy: BCSCalligraphy,
  poligon: bajaPoligono,
  objective:
    'Fortalecer a Baja California Sur como un destino turístico comprometido con el uso, manejo y preservación de sus ecosistemas, su biodiversidad y su cultura; destacando la integración de las comunidades al desarrollo y diversificación de sus actividades económicas, relacionadas al turismo de naturaleza, la incorporación de criterios de conservación de la biodiversidad y las capacidades de las comunidades rurales.',
  path: '/destino/baja-california-sur',
  video: 'https://player.vimeo.com/video/672240142?h=47d38f367c&title=0&byline=0&portrait=0',
  species: [
    {
      name: 'Ballena Jorobada',
      scientific_name: 'Megaptera novaeangliae',
      link: 'https://enciclovida.mx/especies/34429-megaptera-novaeangliae',
      img: 'https://inaturalist-open-data.s3.amazonaws.com/photos/118234627/large.jpg',
      description:
        'Las ballenas jorobadas llegan a medir 15 metros de largo y pesar hasta 41 toneladas, las hembras suelen ser un poco más grandes que los machos, en esta especie de ballena particularmente las aletas pectorales son extremadamente largas y de color blanco en la parte ventral (de ahí su nombre científico mega=grande, ptera=ala, aleta).Son catalogadas como organismos sociables, por lo cual no es raro que se les encuentre en grupos de 7 a 10 individuos. Las migraciones de las ballenas jorobadas abarcan todo el litoral del Pacífico mexicano (se han registrado individuos migrando desde las costas de California hasta las aguas cercanas a Costa Rica).',
    },
    {
      name: 'Mascarita Bajacaliforniana',
      scientific_name: 'Geothlypis beldingi',
      link: 'https://enciclovida.mx/especies/36195-geothlypis-beldingi',
      img: 'https://inaturalist-open-data.s3.amazonaws.com/photos/2960825/large.jpg',
      description:
        'La mascarita peninsular es la más grande de las mascaritas, con un largo total de entre 13.5 y 14 cm y pico relativamente grande y pesado. La subespecie beldingi, la más sureña, tiene pico negruzco, patas color carne, borde detrás de la máscara color amarillo limón, corona y partes superiores amarillo olivo, garganta y partes inferiores amarillo fuerte con un baño ocre en los flancos. Las hembras de estas poblaciones tienen cabeza y partes superiores color olivo, anillo ocular pálido y ceja café-beige pálida, garganta y pecho amarillos haciéndose más blancas en el pecho; cobertoras inferiores amarillas y flancos con un baño café. Machos inmaduros parecidos a las hembras, pero con cara moteada de negro, se puede parecer a los machos adultos en la primavera pero con el borde de las plumas blancuzco. En la subespecie goldmani los machos son, en general, más pálidos, con partes superiores color olivo, garganta y partes inferiores amarillas, con un baño café en los flancos. Los machos tienen el borde detrás de la máscara amarillo limón pálido a casi blanco. Los juveniles en ambas subespecies tienen cabeza y partes superiores de color café olivo con dos barras alares canela pálido, garganta y partes inferiores color beige grisáceo sucio. Su canto es rico y poderoso, parecido al de los cardenales, y suena como whi-ti-wichu whi-ti-wichu whi-ti-wichu o whi ti-wi-chu whi ti-wi-chu whi-chu, o whi-t-wi-chu, whi-t-wi-chu, whi-t-wich, la principal frase repetida unas dos o tres veces (Bryant 1889, Wilbur 1987, Howell & Webb 1995).',
    },
    {
      name: 'Cuclillo pico amarillo',
      scientific_name: 'Coccyzus americanus',
      link: 'https://enciclovida.mx/especies/35856-coccyzus-americanus',
      img: 'https://inaturalist-open-data.s3.amazonaws.com/photos/32994043/large.jpg',
      description:
        'El cuclillo piquigualdo (Coccyzus americanus) es un especie de ave cuculiforme perteneciente a la familia de los cucúlidos que vive en América.',
    },
    {
      name: 'Marlin azul',
      scientific_name: 'Makaira nigricans',
      link: 'https://enciclovida.mx/especies/29626-makaira-nigricans',
      img: 'https://inaturalist-open-data.s3.amazonaws.com/photos/161629363/large.jpg',
      description:
        'La aguja azul (Makaira nigricans), también conocida como aguja azul del Atlántico es una especie de marlín que habita en aguas frías del Atlántico y otros mares contiguos. Se localiza tanto en el norte (Canadá) como en el sur (Argentina), así como en el oeste y en el este (desde las islas Azores a Sudáfrica). Sus rutas migratorias obedecen a la búsqueda de alimento y a las tareas de reproducción (el desove se produce en verano y otoño) y pueden ser trasatlánticas o transecuatoriales. Los individuos de esta especie son solitarios y se consideran bastante agresivos por lo que son deseados como trofeos para la pesca deportiva. Alcanzan la madurez sexual entre los dos y cuatro años.',
    },
  ],
  implementation_partner: {
    name: 'ISLA',
    img: islaCover,
    url: 'https://www.isla.org.mx/',
    paragraphs: [
      'Conservación del Territorio Insular Mexicano, A.C. (ISLA) es una Organización de Sociedad Civil de ámbito nacional fundada el 16 de noviembre de 1995 por un grupo de científicos, integrantes de la tribu Conca´ac (Primer presidente y socio fundador) y naturalistas en las Islas del Golfo de California. En 1998 ISLA en asociación con USAID/ The Nature Conservancy realizó el proyecto Complejo Marino e Insular del Sureste de Baja California Sur: Isla Espíritu Santo y el Parque Nacional de la Bahía de Loreto, Parques en Peligro, USAID, TNC, Packard Foundation y HOMELAND Foundation. Ha participado en la elaboración de programas de manejo de: Islas del Golfo de California, Parque Nacional Bahía de Loreto, Parque Nacional Zona Marina del Archipiélago de San Lorenzo y Complejo insular Isla Espíritu Santo. En el 2001 ISLA elaboró el estudio de Tenencia de la Tierra en Loreto como parte del Programa de Ordenamiento Ecológico Territorial (POEL). En 2001 realizó la Planeación Estratégica de la Institución Ambiental de Baja California Sur, SEMARNAT, gobierno de BCS y Homeland Foundation. Uno de los proyectos que sentó un precedente en México fue el Proceso de Conservación y Proceso Expropiatorio del Archipiélago del Espíritu Santo 1995-2003 con la Comunidad Ejido Bonfil, consolidada el 16 de enero de 2003 con el perfeccionamiento del proceso expropiatorio como patrimonio de los mexicanos. Actividades apoyadas por SEMARNAT/CONANP/REFORMA AGRARIA, The Nature Conservancy, la Fundación David and Lucile Packard, la Fundación HOMELAND, Ejido Bonfil de La Paz e ISLA.',
      'Entre 2002 y 2004, ISLA desarrolló un proceso de construcción participativa de buenas prácticas de marinas donde se generó un Manual de Buenas Prácticas de Marinas, insumo para la construcción de la Norma mexicana, Norma NMX-AA-119-SCFI-2006. En 2018 participó en su actualización publicada en junio 2020 NMX-AA-119-SCFI-2020.En 2010, ISLA asesoró el Circuito Vizcaíno-Valle de los Cirios para obtener la certificación de la Norma Mexicana NMX-AA-133-SCFI-2006 Requisitos y especificaciones de sustentabilidad del Ecoturismo apoyada por ISLA, SEMARNAT, MARISLA y CONAFOR. En 2019 dio inicio al circuito ecoturístico certificado en la bahía de La Paz, certificada por la NMX-AA-133-SCFI-2013. Participando en su actualización en 2021.',
      'De 2006 a 2022, ISLA es Punto Focal de la Carta de la Tierra en Baja California Sur, promoviendo los principios y valores en comunidades ejidales e instituciones académicas. En 2015 firmó en Ensenada, BC., un acuerdo con la Dirección General de Educación en Ciencias del Mar, SEP para incluir las 52 escuelas en zonas costeras en el territorio nacional.',
    ],
  },
  partners: [
    {
      name: 'Secretaría de Turismo y Economía (SETUE)',
      url: 'https://setuesbcs.gob.mx/',
      img: setue,
    },
    {
      name: 'Secretaría de Planeación Urbana, Infraestructura, Movilidad, Medio Ambiente y Recursos Naturales (SEPUIMM)',
      url: 'https://sepuimm.bcs.gob.mx/ ',
      img: sepuimm,
    },
    {
      name: 'Municipio de La Paz',
      url: 'https://lapaz.gob.mx/',
      img: ayuntamientoLaPaz,
    },
    {
      name: 'Municipio de Los Cabos',
      url: 'https://www.loscabos.gob.mx/',
      img: ayuntamientoLosCabos,
    },
    {
      name: 'Parque Nacional Zona Marina del Archipiélago del Espíritu Santo, BCS/ CONANP',
      url: 'https://simec.conanp.gob.mx/ficha.php?anp=141&=11',
      img: espirituSanto,
    },
    {
      name: 'Reserva de la Biósfera Sierra La Laguna, BCS/ CONANP',
      url: 'https://simec.conanp.gob.mx/ficha.php?anp=120&=11',
      img: lalaguna,
    },
    {
      name: 'Parque Nacional Cabo Pulmo, BCS/ CONANP',
      url: 'https://simec.conanp.gob.mx/ficha.php?anp=111&=11',
      img: caboPulmo,
    },
    {
      name: 'Comisión Nacional para el Conocimiento y Uso de la Biodiversidad (CONABIO)',
      url: 'https://www.gob.mx/conabio',
      img: conabio,
    },
    {
      name: 'APFF Cabo San Lucas, BCS',
      url: 'https://simec.conanp.gob.mx/ficha.php?anp=113&=11 ',
      img: caboSanLucas,
    },
    {
      name: 'Universidad Autónoma de Baja California Sur',
      url: 'https://www.uabcs.mx/',
      img: uabcs,
    },
  ],
  images: [
    { src: baja1, width: '1024', height: '680' },
    { src: baja2, width: '1024', height: '760' },
    { src: baja3, width: '1024', height: '680' },
    { src: baja4, width: '1024', height: '682' },
    { src: baja5, width: '1024', height: '683' },
    { src: baja6, width: '1024', height: '1536' },
    { src: baja7, width: '1024', height: '683' },
    { src: baja8, width: '1024', height: '753' },
    { src: baja9, width: '1024', height: '768' },
    { src: baja10, width: '1024', height: '576' },
    { src: baja11, width: '1024', height: '683' },
  ],
};

export default bajaSur;
