import Home from './Home.json';
import Common from './Common.json';
import MayaKaan from './Mayakaan';
import BCS from './BCS';
import oaxaca from './Oaxaca';
import About from './About';

const files = {
  Home,
  About,
  Common,
  'maya-kaan': MayaKaan,
  'baja-california-sur': BCS,
  'costa-oaxaca': oaxaca,
};

export default files;
